import { Fragment, useState, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Leads from "../Leads/Leads";
import Lottie from "lottie-react";
import animationData from "./Animation - 1708376403991.json";
import Stats from "../Stats/Stats";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import Login from "../Login/Login";
import Map from "../Map/Map";
import Regions from "../Regions/Regions";
import loadingAnimation from "./Animation - 1709161464827.json";
import KPIs from "../KPIs/Kpis";
import Inbox from "../Inbox/Inbox";

const IsSameOrAfter = require("dayjs/plugin/isSameOrAfter");

dayjs.extend(IsSameOrAfter);

const user = {
  name: "",
  email: "",
  imageUrl:
    "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg",
};

const userNavigation = [{ name: "Sign out", href: "#" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const db = firebase.firestore();
  const [authed, setAuthed] = useState(false);
  const [navigation, setNavigation] = useState([
    { name: "Dashboard", href: "#", current: true },
    { name: "Regions", href: "#", current: false },
    { name: "Map", href: "#", current: false },
  ]);
  const [reloadChildComponents, setReloadChildComponents] = useState(false);
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    daily: 0,
    weekly: 0,
    monthly: 0,
    sources: [
      { id: 0, value: 10, label: "" },
      { id: 1, value: 15, label: "" },
      { id: 2, value: 20, label: "" },
    ],
  });

  const fetchLeads = async () => {
    const now = new Date();

    const snapshot = await db
      .collection("Jared")
      .doc("Pooli")
      .collection("Leads")
      .get();

    const leadsData = [];
    let dailyCount = 0;
    let weeklyCount = 0;
    let monthlyCount = 0;
    let sourceCounts = {}; // Object to keep track of source counts

    for (let index = 0; index < snapshot.docs.length; index++) {
      const doc = snapshot.docs[index];
      const docData = doc.data();
      const docId = doc.id;

      if (docData.source === "Direct" || docData.source === "direct") {
        leadsData.push(docData);
        const leadTime = new Date(docData.time * 1000);
        const timeDiff = now - leadTime;
        const daysDiff = timeDiff / (1000 * 3600 * 24);

        if (daysDiff <= 1) dailyCount++;
        if (daysDiff <= 7) weeklyCount++;
        if (daysDiff <= 30) monthlyCount++;

        // Counting the source
        sourceCounts["Direct"] = (sourceCounts["Direct"] || 0) + 1;
      } else {
        const leadsSnapshot = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(docId)
          .collection("Leads")
          .get();

        for (let index = 0; index < leadsSnapshot.docs.length; index++) {
          const leadDoc = leadsSnapshot.docs[index];
          const leadData = leadDoc.data();
          leadsData.push(leadData);

          const leadTime = new Date(leadData.time * 1000);
          const timeDiff = now - leadTime;
          const daysDiff = timeDiff / (1000 * 3600 * 24);

          if (daysDiff <= 1) dailyCount++;
          if (daysDiff <= 7) weeklyCount++;
          if (daysDiff <= 30) monthlyCount++;

          // Counting the sources from lead.source
          const source = leadData.source;
          sourceCounts[source] = (sourceCounts[source] || 0) + 1;
        }
      }
    }

    const sourcesArray = Object.keys(sourceCounts).map((source, index) => ({
      id: index,
      value: sourceCounts[source],
      label: source,
    }));

    setLeads(leadsData);
    setStats((prevStats) => ({
      ...prevStats,
      daily: dailyCount,
      weekly: weeklyCount,
      monthly: monthlyCount,
      sources: sourcesArray,
    }));
    setTimeout(() => {
      setReloadChildComponents(!reloadChildComponents);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("User is signed in");
        if (user.uid === "M21aANNWwfSNS3TlrvXiqsWk7TC3") {
          setAuthed(true);
          fetchLeads();
        } else {
          // signout
          firebase.auth().signOut();
        }
      } else {
        setAuthed(false);
      }
    });
  }, []);

  const handleChangeNavigation = (name) => {
    setNavigation((prev) => {
      return prev.map((item) => {
        if (item.name === name) {
          return { ...item, current: true };
        } else {
          return { ...item, current: false };
        }
      });
    });
  };

  return authed ? (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="max-w-9xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-28 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex flex-col items-center">
                      <img
                        style={{ width: 80, height: 80 }}
                        src={
                          "https://symbri.com/_next/static/media/animatedLogo.ed05d684.gif"
                        }
                      />
                      <div style={{ fontSize: 11, fontWeight: "bold" }}>
                        Powered By Symbri!
                      </div>
                    </div>

                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <div
                            key={item.name}
                            onClick={() => handleChangeNavigation(item.name)}
                            className={classNames(
                              item.current
                                ? "bg-gray-900 text-white cursor-pointer"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white cursor-pointer",
                              "rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src={user.imageUrl}
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <div
                                    onClick={() =>
                                      item.name === "Sign out"
                                        ? firebase.auth().signOut()
                                        : console.log("")
                                    }
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </div>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      onClick={() => handleChangeNavigation(item.name)}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">
                        {user.name}
                      </div>
                      <div className="text-sm font-medium leading-none text-gray-400">
                        {user.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <main>
          {navigation.find((a) => a.current).name === "Dashboard" ? (
            <div className="mx-auto max-w-9xl py-6 sm:px-6 lg:px-8">
              <Stats
                reloadChildComponents={reloadChildComponents}
                leads={leads}
                stats={stats}
              />
            </div>
          ) : navigation.find((a) => a.current).name === "Map" ? (
            <div className="mx-auto max-w-9xl">
              <Map
                reloadChildComponents={reloadChildComponents}
                leads={leads}
              />
            </div>
          ) : navigation.find((a) => a.current).name === "Regions" ? (
            <div className="mx-auto max-w-9xl py-6 sm:px-6 lg:px-8">
              <Regions
                reloadChildComponents={reloadChildComponents}
                leads={leads}
              />
            </div>
          ) : navigation.find((a) => a.current).name === "KPIs" ? (
            <div className="mx-auto max-w-9xl py-6 sm:px-6 lg:px-8">
              <KPIs
                reloadChildComponents={reloadChildComponents}
                leads={leads}
              />
            </div>
          ) : navigation.find((a) => a.current).name === "Inbox" ? (
            <div className="mx-auto max-w-9xl py-6 sm:px-6 lg:px-8">
              <Inbox
                reloadChildComponents={reloadChildComponents}
                leads={leads}
              />
            </div>
          ) : (
            <div className="mx-auto max-w-9xl py-6 sm:px-6 lg:px-8">
              <Leads
                reloadChildComponents={reloadChildComponents}
                leads={leads}
              />
            </div>
          )}

          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                height: "100vh",
                width: "100vw",
                top: 0,
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Lottie
                style={{ width: 200, height: 200 }}
                animationData={loadingAnimation}
                loop={true}
              />
              <div className="mt-4">
                <h1 className="text-white text-2xl">Loading...</h1>
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  ) : (
    <Login />
  );
}
