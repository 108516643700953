import React from "react";
import { useEffect, useState } from "react";
import Geocoder from "react-native-geocoding";
import GoogleMap from "google-maps-react-markers";
import firebase from "firebase/compat/app";
import SelectRegion from "./SelectRegion";
import { FiXCircle } from "react-icons/fi";
import _, { map, set } from "lodash";
import AutoComplete from "react-google-autocomplete";
import MeasureTool from "measuretool-googlemaps-v3";

const Map = (props) => {
  const db = firebase.firestore();
  const [leads, setLeads] = useState(props.leads);
  const [mapHeight, setMapHeight] = useState("calc(100vh - 80px)");
  const [markers, setMarkers] = useState([]);
  const [reload, setReload] = useState(false);
  const [zipcodes, setZipcodes] = useState([]);
  const [activeBoundries, setActiveBoundries] = useState([]);
  const [mapState, setMapState] = useState();
  const [selectedZipcode, setSelectedZipcode] = useState();
  const [displayZipcode, setDisplayZipcode] = useState(false);
  const [regions, setRegions] = useState([]);
  const [showMarkers, setShowMarkers] = useState(false);
  const [zipcodeSearch, setZipcodeSearch] = useState("");
  const [zipBasePrice, setZipBasePrice] = useState(0);
  const [zipManager, setZipManager] = useState("");
  const [zipNotes, setZipNotes] = useState("");
  const [tempMarkers, setTempMarkers] = useState([]);
  const [mapType, setMapType] = useState("roadmap");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const markersRef = React.useRef([]);
  const [region, setRegion] = useState({
    name: "None",
    color: "purple",
    id: "0",
  });

  useEffect(() => {
    setReload(!reload);
  }, [props.reloadChildComponents]);

  Geocoder.init("AIzaSyAdCqyznNDDWC84JmiS6DouRrGgHnlSzAg");

  const getMarkers = async () => {
    let m = [];

    const customersDoc = await db
      .collection("Jared")
      .doc("Pooli")
      .collection("Customers")
      .get();

    const leads = customersDoc.docs.map((doc) => doc.data());

    setMarkers(leads);
    markersRef.current = leads;
  };

  const getZipcodes = async () => {
    db.collection("Jared")
      .doc("Pooli")
      .collection("Zipcodes")
      .onSnapshot((snapshot) => {
        const zips = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setZipcodes(zips);
        setActiveBoundries(
          zips.map((zip) => ({
            placeId: zip.placeId,
            region: zip.region,
            zipcode: zip.zipcode,
          }))
        );
      });

    db.collection("Jared")
      .doc("Pooli")
      .collection("Regions")
      .onSnapshot((snapshot) => {
        setRegions(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });
  };

  useEffect(() => {
    getMarkers();
    getZipcodes();
    setTimeout(() => {
      //setMapType("roadmap");
    }, 5000);
  }, []);

  useEffect(() => {
    // Create a debounced version of updateBoundariesOnMap
    const debouncedUpdate = _.debounce(() => {
      if (mapState && activeBoundries.length > 0) {
        updateBoundariesOnMap(activeBoundries, region, zipcodeSearch);
        console.log("running boundaries code");
      }
    }, 500); // 500ms delay

    // Call the debounced function
    debouncedUpdate();

    // Cleanup the debounce function on component unmount or when effect dependencies change
    return () => {
      debouncedUpdate.cancel();
    };
  }, [mapState, activeBoundries, region, zipcodeSearch]); // Listen to searchText along with other dependencies

  const handleAddCustomerMarker = async (addressSearch) => {
    try {
      console.log(markersRef.current);
      console.log("Adding marker for address:", addressSearch);
      const geocodedData = await Geocoder.from(addressSearch);
      const location = geocodedData.results[0].geometry.location;

      console.log(location);

      // Find the closest marker
      let closestMarker = null;
      let shortestDistance = Infinity;

      markersRef.current.forEach((marker) => {
        const distance = Math.sqrt(
          Math.pow(location.lat - marker.location.lat, 2) +
            Math.pow(location.lng - marker.location.lng, 2)
        );

        if (distance < shortestDistance) {
          shortestDistance = distance;
          closestMarker = marker;
        }
      });

      let travelTime = null;

      if (closestMarker) {
        const distanceMatrixService =
          new window.google.maps.DistanceMatrixService();
        const response = await new Promise((resolve, reject) => {
          distanceMatrixService.getDistanceMatrix(
            {
              origins: [{ lat: location.lat, lng: location.lng }],
              destinations: [
                {
                  lat: closestMarker.location.lat,
                  lng: closestMarker.location.lng,
                },
              ],
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
              if (status === window.google.maps.DistanceMatrixStatus.OK) {
                resolve(response);
              } else {
                reject(status);
              }
            }
          );
        });

        travelTime = response.rows[0].elements[0].duration.text;
      }

      console.log(travelTime);

      // Drop a marker on the map
      const marker = {
        location: {
          lat: location.lat,
          lng: location.lng,
        },
        CustomerName: addressSearch,
        travelTimeToClosestMarker: travelTime,
        id: "9999",
      };

      // add to temp markers

      setTempMarkers([marker]);
    } catch (error) {
      console.error(error);
    }
  };

  const updateBoundariesOnMap = (boundaries, region, zipcodeSearch) => {
    const featureLayer = mapState.getFeatureLayer("POSTAL_CODE");

    // Convert the array to an object for O(1) lookup times
    let boundariesObject = boundaries
      .filter((a) =>
        region.name === "None" ? true : a.region.id === region.id
      )
      .filter((a) => a.zipcode.includes(zipcodeSearch))
      .reduce((obj, item) => {
        obj[item.placeId] = item;
        return obj;
      }, {});

    featureLayer.style = (options) => {
      const existsData = boundariesObject[options.feature.placeId];
      if (existsData) {
        const featureStyleOptions = {
          strokeColor: "#810FCB",
          strokeOpacity: 1.0,
          strokeWeight: 3.0,
          fillColor:
            existsData.region.name === "None"
              ? "white"
              : regions.find((a) => a.id === existsData.region.id)
              ? regions.find((a) => a.id === existsData.region.id).color
              : "white",
          fillOpacity: 0.5,
        };
        return featureStyleOptions;
      }
    };

    featureLayer.addListener("click", (event) => {
      if (event.features && event.features.length > 0) {
        const clickedFeature = event.features[0];

        setDisplayZipcode(true);
        setSelectedMarker(null);
        const zip = activeBoundries.find(
          (boundary) => boundary.placeId === clickedFeature.placeId
        );
        setSelectedZipcode(zip);
        const zipcodeData = zipcodes.find((a) => a.zipcode === zip.zipcode);
        console.log(zipcodeData);
        setZipBasePrice(
          zipcodeData.basePrice
            ? zipcodeData.basePrice
            : regions.find((a) => a.id === zipcodeData.region.id) &&
              regions.find((a) => a.id === zipcodeData.region.id).basePrice
            ? regions.find((a) => a.id === zipcodeData.region.id).basePrice
            : "0"
        );

        setZipManager(zipcodeData.manager ? zipcodeData.manager : "");

        setZipNotes(
          zipcodeData.notes
            ? zipcodeData.notes
            : regions.find((a) => a.id === zipcodeData.region.id) &&
              regions.find((a) => a.id === zipcodeData.region.id).notes
            ? regions.find((a) => a.id === zipcodeData.region.id).notes
            : ""
        );
      } else {
        console.error("No features found on this click event");
      }
    });
  };

  const handleApiLoaded = async (map, __maps) => {
    setMapState(map);

    const measureTool = new MeasureTool(map, {
      showSegmentLength: true,
      tooltip: false,
      unit: MeasureTool.UnitTypeId.IMPERIAL,
    });

    // Add a click listener to the map
    map.addListener("click", async (e) => {
      // Get the latitude and longitude of the clicked point
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();

      try {
        // Use reverse geocoding to get the address of the clicked location
        const response = await Geocoder.from(lat, lng);
        if (response && response.results && response.results.length > 0) {
          // Find the address component that contains the postal code
          const addressComponents = response.results[0].address_components;
          const postalCodeObj = addressComponents.find((component) =>
            component.types.includes("postal_code")
          );

          // Also find the state from the address components
          const stateObj = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          );

          // Check if the state is Texas
          if (!stateObj || stateObj.short_name !== "TX") {
            console.log(
              "Clicked location is not in Texas. Operation cancelled."
            );
            return; // Early return if not Texas
          }

          if (postalCodeObj) {
            console.log("Clicked zipcode:", postalCodeObj.long_name);

            // Ask if the user wants to add the zipcode
            const userResponse = window.confirm(
              `Do you want to add the zipcode ${postalCodeObj.long_name}?`
            );
            if (userResponse) {
              // Add the zipcode to the database
              try {
                const res = await fetch(
                  "https://us-central1-symbri-production.cloudfunctions.net/getPlaceIdFromZip",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      token: "",
                      zipcode: postalCodeObj.long_name,
                    }),
                  }
                );

                const data = await res.json();

                if (data.message === "Failed") {
                  alert("Error adding zipcode, could not find place ID.");
                  return;
                } else {
                  const placeId = data.placeId;
                  console.log("Place ID:", placeId);
                  db.collection("Jared")
                    .doc("Pooli")
                    .collection("Zipcodes")
                    .doc(postalCodeObj.long_name)
                    .set({
                      zipcode: postalCodeObj.long_name,
                      region: {
                        name: "None",
                        color: "purple",
                        id: "0",
                      },
                      price: 0,
                      placeId,
                    });
                }
              } catch (error) {
                console.error("Error adding zipcode:", error);
              }
            } else {
              console.log("User chose not to add the zipcode.");
            }
          } else {
            console.log("No zipcode found for this location.");
          }
        } else {
          console.log("No results found for this location.");
        }
      } catch (error) {
        console.error("Error fetching location details:", error);
      }
    });
  };

  const updateRegion = (region) => {
    let currentSelectedZipcode = selectedZipcode;
    currentSelectedZipcode.region = region;
    setSelectedZipcode(currentSelectedZipcode);
    db.collection("Jared")
      .doc("Pooli")
      .collection("Zipcodes")
      .doc(selectedZipcode.zipcode)
      .update({
        region: region,
      });
  };

  const handleEditZipBasePrice = (zipcode, price) => {
    setZipBasePrice(price);
    db.collection("Jared")
      .doc("Pooli")
      .collection("Zipcodes")
      .doc(zipcode)
      .update({
        basePrice: price,
      });
  };

  const handleEditZipNotes = (zipcode, notes) => {
    setZipNotes(notes);
    db.collection("Jared")
      .doc("Pooli")
      .collection("Zipcodes")
      .doc(zipcode)
      .update({
        notes: notes,
      });
  };

  const handleEditZipcodeManager = (zipcode, manager) => {
    setZipManager(manager);
    db.collection("Jared")
      .doc("Pooli")
      .collection("Zipcodes")
      .doc(zipcode)
      .update({
        manager: manager,
      });
  };

  return (
    <div
      id="map"
      key={mapType}
      className="w-full overflow-hidden shadow-lg flex justify-center items-center"
      style={{ height: mapHeight }}
    >
      <GoogleMap
        apiKey="AIzaSyAdCqyznNDDWC84JmiS6DouRrGgHnlSzAg"
        defaultCenter={{
          lat: 29.424349,
          lng: -98.491142,
        }}
        defaultZoom={5}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        options={{
          fullscreenControl: false,
          zoomControl: true,
          clickableIcons: false,
          disableDefaultUI: true,
          mapId: "13f11b75006ee280",
          mapTypeId: mapType,
        }}
      >
        {markers
          .filter((a) => showMarkers)
          .map((marker, index) => (
            <div
              lat={marker.location.lat}
              lng={marker.location.lng}
              onClick={(e) => {
                console.log("Marker clicked");
                e.stopPropagation();
                console.log(marker);
                setSelectedMarker(marker);
              }}
              style={{
                height: marker.travelTimeToClosestMarker ? 50 : 40,
                width: marker.travelTimeToClosestMarker ? 50 : 40,
                backgroundColor: "#202937",
                border: marker.travelTimeToClosestMarker
                  ? "4px solid green"
                  : "4px solid rgb(41, 186, 230)",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 10,
              }}
              key={marker.location.lng + marker.location.lat}
            >
              {marker.travelTimeToClosestMarker
                ? marker.travelTimeToClosestMarker
                : (
                    marker.CustomerName.split(" ")[0]?.substring(0, 1) +
                    marker.CustomerName.split(" ")[1]?.substring(0, 1)
                  ).toUpperCase()}
            </div>
          ))}
        {tempMarkers.map((marker, index) => (
          <div
            lat={marker.location.lat}
            lng={marker.location.lng}
            style={{
              height: 50,
              width: 50,
              backgroundColor: "#202937",
              border: marker.travelTimeToClosestMarker
                ? "4px solid green"
                : "4px solid rgb(41, 186, 230)",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 10,
            }}
            key={marker.location.lng + marker.location.lat}
          >
            {marker.travelTimeToClosestMarker}
          </div>
        ))}
      </GoogleMap>
      <div>
        <div className="absolute top-48 left-4 p-4 flex items-center bg-[#202937] h-12 w-[150px]">
          <div className="flex justify-end items-center">
            <label
              htmlFor="view5days"
              className="mr-2 text-sm font-medium text-white"
            >
              Show Markers
            </label>
            <input
              type="checkbox"
              id="view5days"
              checked={showMarkers}
              onChange={() => setShowMarkers(!showMarkers)}
              className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        </div>
        <div className="absolute top-32 left-4 p-4 flex items-center bg-[#202937] h-12 w-[150px]">
          <div className="flex justify-end items-center">
            <label
              htmlFor="view5days"
              className="mr-2 text-sm font-medium text-white"
            >
              Satellite View
            </label>
            <input
              type="checkbox"
              id="view5days"
              checked={mapType === "satellite"}
              onChange={() =>
                setMapType(mapType === "roadmap" ? "satellite" : "roadmap")
              }
              className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        </div>

        <div className="absolute top-4 right-20 p-4 flex items-center">
          <div style={{ width: 220, marginRight: 8 }}>
            <input
              value={zipcodeSearch}
              onChange={(e) => setZipcodeSearch(e.target.value)}
              placeholder="Search zipcodes..."
              style={{
                height: 38,
                borderRadius: 5,
                width: 220,
                marginTop: 3,
                backgroundColor: "#202937",
                border: "2px solid white",
                paddingLeft: 6,
                color: "white",
              }}
            />
          </div>
          <div style={{ width: 220, marginRight: 8 }}>
            <AutoComplete
              apiKey={"AIzaSyAdCqyznNDDWC84JmiS6DouRrGgHnlSzAg"}
              onPlaceSelected={(place) =>
                handleAddCustomerMarker(place.formatted_address)
              }
              onChange={(e) =>
                e.target.value === "" ? setTempMarkers([]) : null
              }
              style={{
                height: 38,
                borderRadius: 5,
                width: 220,
                marginTop: 3,
                backgroundColor: "#202937",
                border: "2px solid white",
                paddingLeft: 6,
                color: "white",
              }}
              options={{
                types: ["address"],
              }}
            />
          </div>
          <div style={{ width: 200 }}>
            <SelectRegion
              setRegion={setRegion}
              regions={regions}
              region={region}
            />
          </div>
        </div>

        {displayZipcode && selectedZipcode && (
          <div
            className="absolute top-32 right-20 bg-[#202937] p-4 shadow-lg"
            style={{ width: "300px" }}
          >
            <div className="absolute right-6 cursor-pointer">
              <FiXCircle onClick={() => setDisplayZipcode(false)} />
            </div>
            <h1 className="text-white text-2xl font-bold pb-4">
              {selectedZipcode.zipcode}
            </h1>

            <SelectRegion
              setRegion={updateRegion}
              regions={regions}
              region={selectedZipcode.region}
            />

            <div className="flex justify-between items-center mt-4">
              <h1 className="text-white text-sm">Base Price:</h1>
              <input
                onChange={(e) =>
                  handleEditZipBasePrice(
                    selectedZipcode.zipcode,
                    e.target.value
                  )
                }
                style={{
                  borderRadius: 3,
                  width: "70%",
                  paddingLeft: 4,
                  backgroundColor: "#202937",
                  borderWidth: 1,
                  borderColor: "white",
                }}
                value={zipBasePrice}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <h1 className="text-white text-sm">Notes:</h1>
              <textarea
                onChange={(e) =>
                  handleEditZipNotes(selectedZipcode.zipcode, e.target.value)
                }
                style={{
                  borderRadius: 3,
                  height: 50,
                  width: "70%",
                  backgroundColor: "#202937",
                  borderWidth: 1,
                  paddingLeft: 4,
                  borderColor: "white",
                }}
                value={zipNotes}
              />
            </div>
            <div className="flex justify-between items-center mt-4">
              <h1 className="text-white text-sm">Manager:</h1>
              <input
                onChange={(e) =>
                  handleEditZipcodeManager(
                    selectedZipcode.zipcode,
                    e.target.value
                  )
                }
                style={{
                  borderRadius: 3,
                  width: "70%",
                  paddingLeft: 4,
                  backgroundColor: "#202937",
                  borderWidth: 1,
                  borderColor: "white",
                }}
                value={zipManager}
              />
            </div>
          </div>
        )}

        {selectedMarker && (
          <div
            className="absolute top-32 right-20 bg-[#202937] p-4 shadow-lg"
            style={{ width: "300px" }}
          >
            <div className="absolute right-6 cursor-pointer">
              <FiXCircle onClick={() => setSelectedMarker(null)} />
            </div>
            <h1 className="text-white text-2xl font-bold pb-4">
              {selectedMarker.CustomerName}
            </h1>

            <h1 className="text-white text-xl font-bold pb-4">
              {Object.keys(selectedMarker.Addresses).map((key) => (
                <div>
                  <h1>{selectedMarker.Addresses[key].PrimaryAddress}</h1>
                </div>
              ))}
            </h1>
            <h1 className="text-white text-xl font-bold pb-4">
              {Object.keys(selectedMarker.Addresses).map((key) => (
                <div>
                  <h1>
                    $
                    {selectedMarker.Addresses[key].WaterBodies[0].RoutePrice ||
                      0}
                  </h1>
                </div>
              ))}
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Map;
