import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import { FiEdit, FiSettings, FiTrash } from "react-icons/fi";
import { CompactPicker, GithubPicker } from "react-color";
import SelectRegionType from "./SelectRegionType";
import SelectParentRegion from "./SelectParentRegion";
import SelectPhone from "./SelectPhone";

function generateRandom12DigitId() {
  // Generate a random number and remove the '0.' prefix
  let randomPart = Math.random().toString().slice(2, 8);
  // Use the current time to get the rest of the digits, ensuring some level of uniqueness
  let timePart = Date.now().toString().slice(-4);
  // Combine both parts to get a 12-digit string
  return randomPart + timePart;
}

export default function ManageRegions(props) {
  const db = firebase.firestore();
  const [open, setOpen] = useState(false);
  const [regions, setRegions] = useState(props.regions);
  const [parentRegions, setParentRegions] = useState(props.parentRegions);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [displayAddRegion, setDisplayAddRegion] = useState(false);
  const [newRegionName, setNewRegionName] = useState("");
  const [newRegionColor, setNewRegionColor] = useState({ hex: "blue" });
  const [displayEditRegion, setDisplayEditRegion] = useState(false);
  const [regionEditData, setRegionEditData] = useState({});
  const [selectedParentRegion, setSelectedParentRegion] = useState({});
  const [newRegionBasePrice, setNewRegionBasePrice] = useState("");
  const [newRegionNotes, setNewRegionNotes] = useState("");
  const [newRegionPhone, setNewRegionPhone] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const [phones, setPhones] = useState(props.phones || []);
  const [editRegionPhone, setEditRegionPhone] = useState("");
  const [manager, setManager] = useState("");
  const [editBasePriceValue, setEditBasePriceValue] = useState("");
  const [editNotesValue, setEditNotesValue] = useState("");
  const closing = useRef(false);
  const [selectedOption, setSelectedOption] = useState({
    name: "Parent Region",
    value: "parent",
  });

  useEffect(() => {
    setOpen(props.open);
    setRegions(props.regions);
    setParentRegions(props.parentRegions);
    setManager(props.manager);
    if (props.open) {
      db.collection("Jared")
        .doc("Pooli")
        .collection("Phones")
        .doc("Numbers")
        .get()
        .then((doc) => {
          if (doc.exists) {
            setPhones(doc.data().Numbers);
          }
        });
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    setDisplayAddRegion(false);
    setDisplayEditRegion(false);
    props.setOpen(false);
  };

  const handleEditColorClicked = (region) => {
    setDisplayColorPicker(true);
    setSelectedRegion(region);
  };

  const handleColorPicked = (color) => {
    let newRegions = regions.map((region) => {
      if (region.id === regionEditData.region.id) {
        return { ...region, color: color.hex };
      }
      return region;
    });
    setRegions(newRegions);
    setRegionEditData({
      ...regionEditData,
      region: { ...regionEditData.region, color: color.hex },
    });

    db.collection("Jared")
      .doc("Pooli")
      .collection("Regions")
      .doc(regionEditData.region.id)
      .update({
        color: color.hex,
      });
  };

  const handleRegionPhonePicked = (phone) => {
    if (regionEditData.region) {
      let newRegions = regions.map((region) => {
        if (region.id === regionEditData.region.id) {
          return { ...region, phone: phone };
        }
        return region;
      });
      setRegions(newRegions);
      setRegionEditData({
        ...regionEditData,
        region: { ...regionEditData.region, phone: phone },
      });
      db.collection("Jared")
        .doc("Pooli")
        .collection("Regions")
        .doc(regionEditData.region.id)
        .update({
          phone: phone,
        });
    }
  };

  const handleParentRegionPicked = (parentRegionId) => {
    if (regionEditData.region) {
      let newRegions = regions.map((region) => {
        if (region.id === regionEditData.region.id) {
          return { ...region, parentId: parentRegionId };
        }
        return region;
      });
      setRegions(newRegions);
      setRegionEditData({
        ...regionEditData,
        region: { ...regionEditData.region, parentId: parentRegionId },
      });
      db.collection("Jared")
        .doc("Pooli")
        .collection("Regions")
        .doc(regionEditData.region.id)
        .update({
          parentId: parentRegionId,
        });
    }
  };

  useEffect(() => {
    if (!closing.current && regionEditData.region && regionEditData.region.id) {
      if (selectedParentRegion.value) {
        handleParentRegionPicked(selectedParentRegion.value);
      } else {
        try {
          let newRegions = regions.map((region) => {
            if (region.id === regionEditData.region.id) {
              return { ...region, parentId: false };
            }
            return region;
          });
          setRegions(newRegions);
          setRegionEditData({
            ...regionEditData,
            region: { ...regionEditData.region, parentId: false },
          });

          db.collection("Jared")
            .doc("Pooli")
            .collection(
              regionEditData.type === "sub" ? "Regions" : "ParentRegions"
            )
            .doc(regionEditData.region.id)
            .update({
              parentId: false,
            });
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, [selectedParentRegion]);

  const handleAddRegion = () => {
    if (newRegionName === "") {
      alert("Please enter a region name");
      return;
    }

    if (selectedOption.value === "sub") {
      const newRegionId = generateRandom12DigitId();
      let newRegion = {
        name: newRegionName,
        color: newRegionColor.hex,
        id: newRegionId,
        basePrice: newRegionBasePrice,
        notes: newRegionNotes,
        manager,
      };

      setRegions([...regions, newRegion]);

      db.collection("Jared")
        .doc("Pooli")
        .collection("Regions")
        .doc(newRegionId)
        .set({
          name: newRegionName,
          color: newRegionColor.hex,
          id: newRegionId,
          basePrice: newRegionBasePrice,
          notes: newRegionNotes,
          manager,
        });
      setNewRegionName("");
      setNewRegionBasePrice("");
      setNewRegionNotes("");
      setNewRegionColor("blue");
      setManager("");
      setDisplayAddRegion(false);
    } else {
      const newRegionId = generateRandom12DigitId();
      let newRegion = {
        name: newRegionName,
        id: newRegionId,
        basePrice: newRegionBasePrice,
        notes: newRegionNotes,
      };

      setParentRegions([...parentRegions, newRegion]);

      db.collection("Jared")
        .doc("Pooli")
        .collection("ParentRegions")
        .doc(newRegionId)
        .set({
          name: newRegionName,
          id: newRegionId,
          basePrice: newRegionBasePrice,
          notes: newRegionNotes,
          manager,
        });
      setNewRegionName("");
      setNewRegionColor("blue");
      setNewRegionBasePrice("");
      setNewRegionNotes("");
      setManager("");
      setSelectedOption({
        name: "Parent Region",
        value: "parent",
      });
      setDisplayAddRegion(false);
    }
  };

  const handleDeleteRegion = async (regionData) => {
    let region = regionData.region;
    if (regionData.type === "sub") {
      let newRegions = regions.filter((r) => r.id !== region.id);
      setRegions(newRegions);
      try {
        await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Regions")
          .doc(region.id)
          .delete();
        console.log(region.id);
      } catch (error) {
        console.log("Error deleting region: ", error);
      }
    } else {
      let newRegions = parentRegions.filter((r) => r.id !== region.id);
      setParentRegions(newRegions);
      try {
        await db
          .collection("Jared")
          .doc("Pooli")
          .collection("ParentRegions")
          .doc(region.id)
          .delete();
        console.log(region.id);
      } catch (error) {
        console.log("Error deleting region: ", error);
      }
    }
    setDisplayEditRegion(false);
  };

  const handleEditRegion = (region, type) => {
    setDisplayEditRegion(true);
    console.log(regionEditData.phone);
    setSelectedPhone(region.phone || "None");
    setRegionEditData({ type, region });
    setEditBasePriceValue(region.basePrice || "");
    setEditNotesValue(region.notes || "");
    setManager(region.manager || "");
    console.log(region.notes);
  };

  useEffect(() => {
    if (
      regionEditData.region &&
      !closing.current &&
      editBasePriceValue.length > 0 &&
      editNotesValue.length > 0
    ) {
      let newRegions = regions.map((region) => {
        if (region.id === regionEditData.region.id) {
          return {
            ...region,
            basePrice: editBasePriceValue,
            notes: editNotesValue,
            manager: manager || "",
          };
        }
        return region;
      });
      setRegions(newRegions);
      setRegionEditData({
        ...regionEditData,
        region: {
          ...regionEditData.region,
          basePrice: editBasePriceValue,
          notes: editNotesValue,
          manager: manager || "",
        },
      });

      db.collection("Jared")
        .doc("Pooli")
        .collection(regionEditData.type === "sub" ? "Regions" : "ParentRegions")
        .doc(regionEditData.region.id)
        .update({
          basePrice: editBasePriceValue,
          notes: editNotesValue,
          manager: manager || "",
        });
    }
  }, [editBasePriceValue, editNotesValue, manager]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => console.log("")}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-[#202937] py-6 px-4 sm:px-6 border border-white">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Manage Regions
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XCircleIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Manage all your regions here.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="px-4 sm:px-6">
                          <div className="space-y-6 pt-6">
                            {!displayEditRegion && !displayAddRegion && (
                              <div className="mt-0 overflow-y-scroll h-[30vh]">
                                <label
                                  htmlFor="project-name"
                                  className="block text-md font-medium text-gray-900"
                                >
                                  Regions:
                                </label>
                                {parentRegions.map((region) => (
                                  <div
                                    key={region.id}
                                    className="flex flex-col mt-2"
                                  >
                                    <div className="flex items-center w-full">
                                      <div>{region.name}</div>

                                      <div
                                        onClick={() =>
                                          handleEditRegion(region, "parent")
                                        }
                                        style={{ marginLeft: "auto" }}
                                        className="text-[#202937] cursor-pointer"
                                      >
                                        <FiSettings size={20} />
                                      </div>
                                    </div>
                                    <div>
                                      {regions
                                        .filter((a) => a.parentId === region.id)
                                        .map((subRegion) => (
                                          <div
                                            key={subRegion.id}
                                            className="text-sm mt-2"
                                            style={{ color: subRegion.color }}
                                          >
                                            - {subRegion.name}
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            {!displayEditRegion && !displayAddRegion && (
                              <div className="mt-0 overflow-y-scroll h-[30vh]">
                                <label
                                  htmlFor="project-name"
                                  className="block text-md font-medium text-gray-900"
                                >
                                  Sub-Regions:
                                </label>
                                {regions.map((region) => (
                                  <div
                                    key={region.id}
                                    className="flex items-center mt-2"
                                  >
                                    <div style={{ color: region.color }}>
                                      {region.name}
                                    </div>
                                    <div
                                      onClick={() =>
                                        handleEditRegion(region, "sub")
                                      }
                                      style={{ marginLeft: "auto" }}
                                      className="text-[#202937] cursor-pointer"
                                    >
                                      <FiSettings size={20} />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            {displayEditRegion && (
                              <div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    {regionEditData.type === "sub"
                                      ? "Sub Region Name"
                                      : "Region Name"}
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      style={{ borderRadius: 5 }}
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={regionEditData.region.name}
                                      disabled
                                    />
                                  </div>
                                </div>

                                {regionEditData.type === "sub" && (
                                  <div className="mt-4">
                                    <label
                                      htmlFor="project-name"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Parent Region
                                    </label>
                                    <div className="mt-1">
                                      <SelectParentRegion
                                        selectedOption={selectedParentRegion}
                                        regionEditData={regionEditData}
                                        setSelectedOption={
                                          setSelectedParentRegion
                                        }
                                        parentRegions={parentRegions}
                                      />
                                    </div>
                                  </div>
                                )}
                                {regionEditData.type === "sub" && (
                                  <div className="mt-4">
                                    <label
                                      htmlFor="project-name"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Region Phone
                                    </label>
                                    <div className="mt-1">
                                      <SelectPhone
                                        selectedOption={selectedPhone}
                                        regionEditData={regionEditData}
                                        setSelectedOption={setSelectedPhone}
                                        phones={phones}
                                        handleRegionPhonePicked={
                                          handleRegionPhonePicked
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                <div className="mt-4">
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Base Price
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      style={{ borderRadius: 5 }}
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={editBasePriceValue}
                                      onChange={(e) =>
                                        setEditBasePriceValue(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="mt-4">
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Notes
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      style={{ borderRadius: 5 }}
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={editNotesValue}
                                      onChange={(e) =>
                                        setEditNotesValue(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="mt-4">
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Manager
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      style={{ borderRadius: 5 }}
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={manager}
                                      onChange={(e) =>
                                        setManager(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                {regionEditData.type === "sub" && (
                                  <div className="mt-4">
                                    <label
                                      htmlFor="project-name"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Sub Region Color
                                    </label>
                                    <div className="mt-1">
                                      <CompactPicker
                                        colors={[
                                          "#F44336",
                                          "#E91E63",
                                          "#9C27B0",
                                          "#673AB7",
                                          "#3F51B5",
                                          "#2196F3",
                                          "#03A9F4",
                                          "#00BCD4",
                                          "#009688",
                                          "#4CAF50",
                                          "#8BC34A",
                                          "#CDDC39",
                                          "#FFEB3B",
                                          "#FFC107",
                                          "#FF9800",
                                          "#FF5722",
                                          "#795548",
                                          "#607D8B",
                                          "#00FF00",
                                          "#0000FF",
                                          "#FF00FF",
                                          "#FF0000",
                                          "#008000",
                                          "#FFFF00",
                                          "#800080",
                                          "#808000",
                                          "#008080",
                                          "#FFA500",
                                          "#FFC0CB",
                                          "#FFD700",
                                          "#FF4500",
                                        ]}
                                        color={regionEditData.region.color}
                                        onChangeComplete={handleColorPicked}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {displayAddRegion && (
                              <div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Region Name
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      style={{ borderRadius: 5 }}
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={newRegionName}
                                      onChange={(e) =>
                                        setNewRegionName(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="mt-4">
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Base Price
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      style={{ borderRadius: 5 }}
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={newRegionBasePrice}
                                      onChange={(e) =>
                                        setNewRegionBasePrice(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="mt-4">
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Notes
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      style={{ borderRadius: 5 }}
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={newRegionNotes}
                                      onChange={(e) =>
                                        setNewRegionNotes(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="mt-4">
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Manager
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      style={{ borderRadius: 5 }}
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={manager}
                                      onChange={(e) =>
                                        setManager(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="mt-4">
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Region Type
                                  </label>
                                  <div className="mt-1">
                                    <SelectRegionType
                                      selectedOption={selectedOption}
                                      setSelectedOption={setSelectedOption}
                                    />
                                  </div>
                                </div>
                                {selectedOption.value === "sub" && (
                                  <div className="mt-4">
                                    <label
                                      htmlFor="project-name"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Sub Region Color
                                    </label>
                                    <div className="mt-1">
                                      <CompactPicker
                                        colors={[
                                          "#F44336",
                                          "#E91E63",
                                          "#9C27B0",
                                          "#673AB7",
                                          "#3F51B5",
                                          "#2196F3",
                                          "#03A9F4",
                                          "#00BCD4",
                                          "#009688",
                                          "#4CAF50",
                                          "#8BC34A",
                                          "#CDDC39",
                                          "#FFEB3B",
                                          "#FFC107",
                                          "#FF9800",
                                          "#FF5722",
                                          "#795548",
                                          "#607D8B",
                                          "#00FF00",
                                          "#0000FF",
                                          "#FFFF00",
                                          "#FF00FF",
                                          "#00FFFF",
                                          "#FF0000",
                                          "#008000",
                                          "#0000FF",
                                          "#FFFF00",
                                          "#800080",
                                          "#808000",
                                          "#008080",
                                          "#FFA500",
                                          "#FFC0CB",
                                          "#FFD700",
                                          "#FF4500",
                                        ]}
                                        color={newRegionColor}
                                        onChangeComplete={(color) =>
                                          setNewRegionColor(color)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      {!displayEditRegion && (
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-[#202937] py-2 px-4 text-sm font-medium text-white shadow-sm mr-2"
                          onClick={() =>
                            displayAddRegion
                              ? handleAddRegion()
                              : setDisplayAddRegion(true)
                          }
                        >
                          {displayAddRegion ? "Save" : "Add Region"}
                        </button>
                      )}
                      {displayEditRegion && (
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-[red] py-2 px-4 text-sm font-medium text-white shadow-sm mr-2"
                          onClick={() => handleDeleteRegion(regionEditData)}
                        >
                          Delete
                        </button>
                      )}
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() =>
                          displayAddRegion
                            ? setDisplayAddRegion(false)
                            : displayEditRegion
                            ? (() => {
                                closing.current = true;
                                setDisplayEditRegion(false);
                                setEditNotesValue("");
                                setEditBasePriceValue("");
                                setSelectedParentRegion({});
                                setSelectedPhone("");
                                setTimeout(() => {
                                  closing.current = false;
                                }, 1500);
                              })()
                            : handleClose(false)
                        }
                      >
                        {displayAddRegion
                          ? "Cancel"
                          : displayEditRegion
                          ? "Go Back"
                          : "Close"}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          {displayColorPicker && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                height: "100vh",
                width: "100vw",
                backgroundColor: "rgba(0,0,0,0.5)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  alignItems: "center",
                  padding: 14,
                }}
              >
                <div className="text-black mb-4">Pick a Color</div>
                <CompactPicker
                  colors={[
                    "#F44336",
                    "#E91E63",
                    "#9C27B0",
                    "#673AB7",
                    "#3F51B5",
                    "#2196F3",
                    "#03A9F4",
                    "#00BCD4",
                    "#009688",
                    "#4CAF50",
                    "#8BC34A",
                    "#CDDC39",
                    "#FFEB3B",
                    "#FFC107",
                    "#FF9800",
                    "#FF5722",
                    "#795548",
                    "#607D8B",
                    "#00FF00",
                    "#0000FF",
                    "#FFFF00",
                    "#FF00FF",
                    "#00FFFF",
                    "#FF0000",
                    "#008000",
                    "#0000FF",
                    "#FFFF00",
                    "#800080",
                    "#808000",
                    "#008080",
                    "#FFA500",
                    "#FFC0CB",
                    "#FFD700",
                    "#FF4500",
                  ]}
                  color={"blue"}
                  onChangeComplete={handleColorPicked}
                />
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
