import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Letter } from "react-letter";
import { extract } from "letterparser";
import { FiSend, FiXCircle } from "react-icons/fi";
import { stateToHTML } from "draft-js-export-html";
import firebase from "firebase/compat/app";
import { stripHtml } from "string-strip-html";
import dayjs from "dayjs";

const NewEmail = ({
  open,
  setOpen,
  emailData,
  leadData,
  originalMessageId,
}) => {
  const [editorState, setEditorState] = useState("");
  const db = firebase.firestore();

  function removeHtmlFromString(inputString) {
    // Use the stripHtml function to remove HTML content
    const cleanedString = stripHtml(inputString).result; // Get the cleaned result

    return cleanedString;
  }

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
  }, [emailData]);

  const sendEmail = async () => {
    const htmlContent = stateToHTML(editorState.getCurrentContent());

    const token = await firebase.auth().currentUser.getIdToken();

    const res = await fetch(
      "https://us-central1-symbri-production.cloudfunctions.net/jaredSendEmail",
      {
        method: "POST",
        body: JSON.stringify({
          emailData: {
            html: htmlContent,
            body: removeHtmlFromString(htmlContent),
            subject: emailData[0]?.subject || "New Email From Poolie!",
            to: leadData.leadEmail,
            messageId: emailData[0]?.messageId,
            references: emailData[0]?.references,
          },
          token: token,
          type: "reply",
        }),
      }
    );

    if (res.ok) {
      const sentEmailData = await res.json();

      console.log(sentEmailData);

      const messageId = sentEmailData.info.messageId;

      const references = sentEmailData.info.references;

      // need to also add to db.

      if (leadData.source.toLowerCase() === "direct") {
        const emailRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.leadEmail)
          .get();

        const phoneRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(
            leadData.phone.includes("+1")
              ? leadData.phone
              : "+1" + leadData.phone
          )
          .get();

        db.collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(
            emailRef.exists
              ? leadData.leadEmail
              : phoneRef.exists
              ? leadData.phone.includes("+1")
                ? leadData.phone
                : "+1" + leadData.phone
              : "New"
          )
          .update({
            emails: firebase.firestore.FieldValue.arrayUnion({
              from: "swim@pooli.com",
              to: leadData.leadEmail,
              html: htmlContent,
              text: "Reply from Pooli",
              subject:
                emailData[emailData.length - 1]?.subject ||
                "New Email From Poolie!",
              time: dayjs().unix(),
              messageId: messageId,
              references: references || "",
            }),
          });
      } else {
        db.collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.source)
          .collection("Leads")
          .doc(leadData.leadId)
          .update({
            emails: firebase.firestore.FieldValue.arrayUnion({
              from: "swim@pooli.com",
              to: leadData.leadEmail,
              html: htmlContent,
              text: "Reply from Pooli",
              subject:
                emailData[emailData.length - 1]?.subject ||
                "New Email From Poolie!",
              time: dayjs().unix(),
              messageId: messageId,
              references: references || "",
            }),
          });
      }
    } else {
      console.log("Error sending email");
      alert("Error sending email");
    }

    setEditorState("");
    setOpen(false);
  };

  return (
    <div
      style={{
        display: open ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          alignItems: "center",
          padding: 14,
          height: 800,
          width: 800,
          borderRadius: 5,
        }}
      >
        <div
          style={{
            border: "1px solid black",
            borderRadius: 5,
          }}
        >
          <Editor
            editorState={editorState}
            onEditorStateChange={(editorState) => setEditorState(editorState)}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorStyle={{ height: 650, paddingLeft: 10 }}
          />
          <div style={{ display: "flex" }}>
            <div
              onClick={() => setOpen(false)}
              style={{
                height: 35,
                width: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "red",
                borderRadius: 5,
                marginLeft: "auto",
                marginRight: 10,
                marginBottom: 10,
                cursor: "pointer",
              }}
            >
              <div style={{ color: "white", fontSize: 14 }}>Close</div>
            </div>
            <div
              onClick={sendEmail}
              style={{
                height: 35,
                width: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#202937",
                borderRadius: 5,
                marginRight: 10,
                marginBottom: 10,
                cursor: "pointer",
              }}
            >
              <div style={{ color: "white", fontSize: 14 }}>Send</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewEmail;
