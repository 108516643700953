import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import SelectRegion from "./SelectRegion";

export default function EditZip(props) {
  const db = firebase.firestore();
  const [open, setOpen] = useState(false);
  const [zipcode, setZipcode] = useState("");
  const [manager, setManager] = useState("");
  const [region, setRegion] = useState({
    name: "None",
    color: "purple",
    id: "0",
  });
  const [price, setPrice] = useState("");

  useEffect(() => {
    setOpen(props.open);

    if (props.open) {
      setZipcode(props.zipcode);
      setRegion(props.region);
      setPrice(props.price);
      setManager(props.manager);
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };

  const addZip = () => {
    if (zipcode === "" || price === "") {
      alert("Please fill in all fields");
      return;
    }

    db.collection("Jared")
      .doc("Pooli")
      .collection("Zipcodes")
      .doc(zipcode)
      .update({
        zipcode: zipcode,
        region: region,
        price: price,
        manager: manager,
      })
      .then(() => {
        setZipcode("");
        setRegion("");
        setPrice("");
        setManager("");
        handleClose();
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-[#202937] py-6 px-4 sm:px-6 border border-white">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Edit Zipcode
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XCircleIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Edit an existing zipcode in the database here.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Zipcode
                              </label>
                              <div className="mt-1">
                                <input
                                  style={{ borderRadius: 5 }}
                                  className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  value={zipcode}
                                  onChange={(e) => setZipcode(e.target.value)}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Region
                              </label>
                              <div className="mt-1">
                                <SelectRegion
                                  setRegion={setRegion}
                                  regions={props.regions}
                                  region={region}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Price
                              </label>
                              <div className="mt-1">
                                <input
                                  style={{ borderRadius: 5 }}
                                  className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  value={price}
                                  onChange={(e) => setPrice(e.target.value)}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Manager
                              </label>
                              <div className="mt-1">
                                <input
                                  style={{ borderRadius: 5 }}
                                  className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  value={manager}
                                  onChange={(e) => setManager(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-[#202937] py-2 px-4 text-sm font-medium text-white shadow-sm mr-2"
                        onClick={() => addZip()}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
