import React, { useState, useEffect, useRef } from "react";
import { FiCheckCircle, FiTrash } from "react-icons/fi";
import dayjs from "dayjs";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import firebase from "firebase/compat/app";

export default function KPIs(props) {
  const [regions, setRegions] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);
  const [displayManageRegions, setDisplayManageRegions] = useState(false);
  const [search, setSearch] = useState("");
  const [reload, setReload] = useState(false);
  const [kpiData, setKpiData] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [region, setRegion] = useState({
    name: "None",
    color: "purple",
    id: "0",
  });

  const db = firebase.firestore();

  const extractWeekDetails = (data) => {
    return data.map((weekData, index) => ({
      week: index + 1,
      startDate: weekData.startDate,
      endDate: weekData.endDate,
    }));
  };

  const reformatData = (data) => {
    const regionDataMap = {};
    const weeks = data.map((weekData, index) => ({
      name: `Week ${index + 1}`,
      startDate: weekData.startDate,
      endDate: weekData.endDate,
    }));
    const allRegionIds = new Set();

    data.forEach((weekData) => {
      weekData.kpiResult.forEach(({ regionData }) => {
        allRegionIds.add(regionData.id);
        if (!regionDataMap[regionData.id]) {
          regionDataMap[regionData.id] = { ...regionData, weekData: [] };
        }
      });
    });

    allRegionIds.forEach((regionId) => {
      weeks.forEach((week) => {
        regionDataMap[regionId].weekData.push({
          week: week.name,
          startDate: week.startDate,
          endDate: week.endDate,
          total: 0,
          completedJobs: 0,
          averageRevenue: 0,
        });
      });
    });

    data.forEach((weekData, weekIndex) => {
      const weekName = `Week ${weekIndex + 1}`;
      weekData.kpiResult.forEach((regionResult) => {
        const { regionData } = regionResult;
        const regionId = regionData.id;
        const weekDataIndex = weeks.findIndex((week) => week.name === weekName);
        regionDataMap[regionId].weekData[weekDataIndex] = {
          week: weekName,
          startDate: weekData.startDate,
          endDate: weekData.endDate,
          ...regionResult,
        };
      });
    });

    return Object.values(regionDataMap);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await db
        .collection("Jared")
        .doc("Pooli")
        .collection("KPIs")
        .get();

      const mappedData = data.docs.map((doc) => ({ ...doc.data() }));
      const weekDetails = extractWeekDetails(mappedData);
      const reformattedData = reformatData(mappedData);
      setKpiData(reformattedData);
      setWeeks(weekDetails);
      console.log(weekDetails);
    };
    fetchData();
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="flex flex-row items-center">
          <h1 className="text-xl font-semibold text-gray-900">
            Key Performance Indicators
          </h1>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 flex"
                    >
                      Region
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Week 1</div>
                      <div style={{ fontSize: 11 }}>
                        {weeks[0]?.startDate} -{" "}
                      </div>
                      <div style={{ fontSize: 11 }}>{weeks[0]?.endDate}</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Week 2</div>
                      <div style={{ fontSize: 11 }}>
                        {weeks[1]?.startDate} -{" "}
                      </div>
                      <div style={{ fontSize: 11 }}>{weeks[1]?.endDate}</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Week 3</div>
                      <div style={{ fontSize: 11 }}>
                        {weeks[2]?.startDate} -{" "}
                      </div>
                      <div style={{ fontSize: 11 }}>{weeks[2]?.endDate}</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Week 4</div>
                      <div style={{ fontSize: 11 }}>
                        {weeks[3]?.startDate} -{" "}
                      </div>
                      <div style={{ fontSize: 11 }}>{weeks[3]?.endDate}</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Week 5</div>
                      <div style={{ fontSize: 11 }}>
                        {weeks[4]?.startDate} -{" "}
                      </div>
                      <div style={{ fontSize: 11 }}>{weeks[4]?.endDate}</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Week 6</div>
                      <div style={{ fontSize: 11 }}>
                        {weeks[5]?.startDate} -{" "}
                      </div>
                      <div style={{ fontSize: 11 }}>{weeks[5]?.endDate}</div>
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Week 7</div>
                      <div style={{ fontSize: 11 }}>
                        {weeks[6]?.startDate} -{" "}
                      </div>
                      <div style={{ fontSize: 11 }}>{weeks[6]?.endDate}</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Week 8</div>
                      <div style={{ fontSize: 11 }}>
                        {weeks[7]?.startDate} -{" "}
                      </div>
                      <div style={{ fontSize: 11 }}>{weeks[7]?.endDate}</div>
                    </th>
                  </tr>
                </thead>

                <tbody
                  key={reload}
                  className="divide-y divide-gray-200 bg-white"
                >
                  {kpiData.map((data) => (
                    <tr>
                      <td className="whitespace py-4 pl-4 pr-3 text-[0.78vw] font-medium text-gray-900 sm:pl-6 text-left">
                        {data.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-[0.78vw] text-black text-left">
                        <div>
                          Completed Jobs: {data.weekData[0].completedJobs}
                        </div>
                        <div>
                          Jobs Revenue: ${Math.round(data.weekData[0].total)}
                        </div>
                        <div>
                          AVG Job Rev: $
                          {Math.round(data.weekData[0].averageRevenue)}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-[0.78vw] text-black text-left">
                        <div>
                          Completed Jobs: {data.weekData[1].completedJobs}
                        </div>
                        <div>
                          Jobs Revenue: ${Math.round(data.weekData[1].total)}
                        </div>
                        <div>
                          AVG Job Rev: $
                          {Math.round(data.weekData[1].averageRevenue)}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-[0.78vw] text-black text-left">
                        <div>
                          Completed Jobs: {data.weekData[2].completedJobs}
                        </div>
                        <div>
                          Jobs Revenue: ${Math.round(data.weekData[2].total)}
                        </div>
                        <div>
                          AVG Job Rev: $
                          {Math.round(data.weekData[2].averageRevenue)}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-[0.78vw] text-black text-left">
                        <div>
                          Completed Jobs: {data.weekData[3].completedJobs}
                        </div>
                        <div>
                          Jobs Revenue: ${Math.round(data.weekData[3].total)}
                        </div>
                        <div>
                          AVG Job Rev: $
                          {Math.round(data.weekData[3].averageRevenue)}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-[0.78vw] text-black text-left">
                        <div>
                          Completed Jobs: {data.weekData[4].completedJobs}
                        </div>
                        <div>
                          Jobs Revenue: ${Math.round(data.weekData[4].total)}
                        </div>
                        <div>
                          AVG Job Rev: $
                          {Math.round(data.weekData[4].averageRevenue)}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-[0.78vw] text-black text-left">
                        <div>
                          Completed Jobs: {data.weekData[5].completedJobs}
                        </div>
                        <div>
                          Jobs Revenue: ${Math.round(data.weekData[5].total)}
                        </div>
                        <div>
                          AVG Job Rev: $
                          {Math.round(data.weekData[5].averageRevenue)}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-[0.78vw] text-black text-left">
                        <div>
                          Completed Jobs: {data.weekData[6].completedJobs}
                        </div>
                        <div>
                          Jobs Revenue: ${Math.round(data.weekData[6].total)}
                        </div>
                        <div>
                          AVG Job Rev: $
                          {Math.round(data.weekData[6].averageRevenue)}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-[0.78vw] text-black text-left">
                        <div>
                          Completed Jobs: {data.weekData[7].completedJobs}
                        </div>
                        <div>
                          Jobs Revenue: ${Math.round(data.weekData[7].total)}
                        </div>
                        <div>
                          AVG Job Rev: $
                          {Math.round(data.weekData[7].averageRevenue)}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
