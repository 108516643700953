import React, { useState, useEffect, useRef } from "react";
import {
  FiArrowLeft,
  FiCheck,
  FiCheckCircle,
  FiCornerDownLeft,
  FiFastForward,
  FiRepeat,
  FiSkipBack,
  FiTrash,
} from "react-icons/fi";
import dayjs from "dayjs";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import firebase from "firebase/compat/app";
import LeadProfile from "./LeadProfile";
import AddLead from "./AddLead";
import SelectOption from "./SelectOption";

export default function Leads(props) {
  const [leads, setLeads] = useState(props.leads);
  const [sortBy, setSortBy] = useState("time");
  const [displayLead, setDisplayLead] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [displayAddLead, setDisplayAddLead] = useState(false);
  const [search, setSearch] = useState("");
  const [reload, setReload] = useState(false);
  const [zipcodes, setZipcodes] = useState([]);
  const [view5Days, setView5Days] = useState(true);
  const [selectedOption, setSelectedOption] = useState({
    name: "Open",
    value: "open",
  });
  const firstLoad = useRef(true);
  const db = firebase.firestore();

  useEffect(() => {
    handleSort(sortBy);
    firstLoad.current = false;
    db.collection("Jared")
      .doc("Pooli")
      .collection("Zipcodes")
      .onSnapshot((snapshot) => {
        setZipcodes(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      });
  }, []);

  useEffect(() => {
    if (!displayLead && !firstLoad.current) {
      fetchLeads();
    }
  }, [displayLead]);

  const fetchLeads = async () => {
    const now = new Date();

    const snapshot = await db
      .collection("Jared")
      .doc("Pooli")
      .collection("Leads")
      .get();

    const leadsData = [];

    for (let index = 0; index < snapshot.docs.length; index++) {
      const doc = snapshot.docs[index];
      const docData = doc.data();
      const docId = doc.id;

      if (docData.source === "Direct" || docData.source === "direct") {
        leadsData.push(docData);
      } else {
        const leadsSnapshot = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(docId)
          .collection("Leads")
          .get();

        for (let index = 0; index < leadsSnapshot.docs.length; index++) {
          const leadDoc = leadsSnapshot.docs[index];
          const leadData = leadDoc.data();
          leadsData.push(leadData);
        }
      }
    }
    console.log(leadsData);
    handleSort(sortBy, leadsData);
  };

  const handleSort = (sortBy, leadsData) => {
    setSortBy(sortBy);
    const leadDataToSort = leadsData || leads;
    const sortedLeads = [...leadDataToSort].sort((a, b) => {
      // Function to handle null or undefined values
      const nullsLastComparator = (x, y) => {
        if (x == null) return 1; // null or undefined values go last
        if (y == null) return -1; // non-null values go first
        return 0; // both values are non-null
      };

      switch (sortBy) {
        case "price":
          return (b.price || 0) - (a.price || 0);
        case "customer":
          const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
          const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
          return (
            nullsLastComparator(nameA, nameB) || nameA.localeCompare(nameB)
          );
        case "source":
          return (
            nullsLastComparator(a.source, b.source) ||
            a.source.localeCompare(b.source)
          );
        case "email":
          return (
            nullsLastComparator(a.leadEmail, b.leadEmail) ||
            a.leadEmail.localeCompare(b.leadEmail)
          );
        case "phone":
          return (
            nullsLastComparator(a.phone, b.phone) ||
            a.phone.localeCompare(b.phone)
          );
        case "address":
          return (
            nullsLastComparator(a.address, b.address) ||
            a.address.localeCompare(b.address)
          );
        default:
          return 0;
      }
    });

    // Update the state with the sorted leads
    setLeads(sortedLeads);
    setReload(!reload);
  };

  const handleViewLead = (lead) => {
    setSelectedLead(lead);
    setDisplayLead(true);
  };

  const handleMarkLeadClosed = (lead) => {
    // Prompt the user to confirm the deletion
    const isConfirmed = window.confirm(
      "Are you sure you want to mark this lead won?"
    );

    // If the user clicks "Cancel", exit the function
    if (!isConfirmed) {
      return;
    }

    // Proceed with deletion if the user confirmed
    if (lead.source === "Direct") {
      // Delete direct lead
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(lead.leadEmail)
        .update({ status: "won" });
    } else {
      // Delete service lead
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(lead.source)
        .collection("Leads")
        .doc(lead.leadId)
        .update({ status: "won" });
    }

    // Refresh the leads list
    fetchLeads();
  };

  const handleMarkLeadOpen = (lead) => {
    // Prompt the user to confirm the deletion
    const isConfirmed = window.confirm(
      "Are you sure you want to mark this lead open?"
    );

    // If the user clicks "Cancel", exit the function
    if (!isConfirmed) {
      return;
    }

    // Proceed with deletion if the user confirmed
    if (lead.source === "Direct") {
      // Delete direct lead
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(lead.leadEmail)
        .update({ status: "open" });
    } else {
      // Delete service lead
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(lead.source)
        .collection("Leads")
        .doc(lead.leadId)
        .update({ status: "open" });
    }

    // Refresh the leads list
    fetchLeads();
  };

  const handleView5DaysChange = () => {
    setView5Days(!view5Days);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="flex flex-row items-center">
          <h1 className="text-xl font-semibold text-gray-900">Leads Tracker</h1>
        </div>
        <div className="ml-auto flex">
          <input
            placeholder="Enter search term..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              border: "1px solid #D1D5DB",
              borderRadius: 5,
              width: 220,
              paddingLeft: 5,
              height: 38,
              color: "black",
              marginTop: 2,
            }}
          />
          <button
            onClick={() => setDisplayAddLead(true)}
            style={{
              width: 100,
              height: 40,
              backgroundColor: "#202937",
              borderRadius: 5,
              marginLeft: 14,
            }}
          >
            Add +
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 flex w-48"
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Lead Source</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Region</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex"></div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex"></div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex"></div>
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <div className="flex justify-end items-center">
                        <label
                          htmlFor="view5days"
                          className="mr-2 text-sm font-medium text-gray-900"
                        >
                          Last 7 days
                        </label>
                        <input
                          type="checkbox"
                          id="view5days"
                          checked={view5Days}
                          onChange={handleView5DaysChange}
                          className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody
                  key={reload}
                  className="divide-y divide-gray-200 bg-white"
                >
                  {leads
                    .filter((a) =>
                      search.length === 0
                        ? true
                        : (a.firstName + " " + a.lastName)
                            .toLowerCase()
                            .includes(search.toLowerCase())
                    )
                    .filter((a) =>
                      view5Days
                        ? dayjs()
                            .startOf("day")
                            .diff(dayjs(a.time * 1000).startOf("day"), "day") <=
                          6
                        : true
                    )
                    .sort((a, b) => b.time - a.time)
                    .map((lead, index) => (
                      <tr key={lead.leadEmail + index}>
                        <td className="whitespace py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-left">
                          {lead.firstName + " " + lead.lastName}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                          {lead.source}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                          {zipcodes.find((a) => a.zipcode === lead.zipcode)
                            ? zipcodes.find((a) => a.zipcode === lead.zipcode)
                                .region.name
                            : "None"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left w-[14%]"></td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left w-[14%]"></td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left w-[14%]"></td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left w-[14%]"></td>
                        <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-r">
                          <div
                            style={{
                              display: "flex",
                              marginRight: "auto",
                              justifyContent: "space-around",
                            }}
                          >
                            <div
                              style={{
                                color: lead.services?.find((a) =>
                                  Object.keys(a).includes("Weekly Pool Care")
                                )
                                  ? "green"
                                  : "grey",
                                marginRight: 20,
                              }}
                            >
                              Weekly Maintenance
                            </div>

                            <div
                              style={{
                                color: lead.services?.find((a) =>
                                  Object.keys(a).includes("Repair")
                                )
                                  ? "green"
                                  : "grey",
                                marginRight: 20,
                              }}
                            >
                              Repair
                            </div>

                            <div
                              style={{
                                color: lead.services?.find((a) =>
                                  Object.keys(a).includes("One Time Clean")
                                )
                                  ? "green"
                                  : "grey",
                                marginRight: 20,
                              }}
                            >
                              OTC
                            </div>
                            <div
                              style={{
                                color: lead.services?.find((a) =>
                                  Object.keys(a).includes("Degreen")
                                )
                                  ? "green"
                                  : "grey",
                                marginRight: 20,
                              }}
                            >
                              Degreen
                            </div>
                            <div
                              style={{
                                color: "grey",
                                marginRight: 20,
                              }}
                            >
                              {dayjs(lead.time * 1000).format("h:mm A")}
                            </div>
                            <div
                              style={{
                                color: "grey",
                                marginRight: 20,
                              }}
                            >
                              {dayjs(lead.time * 1000).format("MM/DD/YYYY")}
                            </div>
                            <div
                              style={{ marginRight: 20 }}
                              onClick={() => handleViewLead(lead)}
                              className="text-[#202937] cursor-pointer"
                            >
                              View
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <LeadProfile
        leadData={selectedLead}
        open={displayLead}
        setOpen={setDisplayLead}
        zipcodes={zipcodes}
        setLeads={setLeads}
        leads={leads}
      />
      <AddLead
        setLeads={setLeads}
        leads={leads}
        open={displayAddLead}
        setOpen={setDisplayAddLead}
      />
    </div>
  );
}
