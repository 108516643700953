import React, { useState, useEffect, useRef } from "react";
import {
  FiArrowDownCircle,
  FiArrowLeft,
  FiArrowUpLeft,
  FiCheck,
  FiCheckCircle,
  FiCornerDownLeft,
  FiFastForward,
  FiRepeat,
  FiSkipBack,
  FiTrash,
} from "react-icons/fi";
import dayjs from "dayjs";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import firebase from "firebase/compat/app";
import LeadProfile from "../Leads/LeadProfile";
import AddLead from "../Leads/AddLead";
import SelectOption from "./SelectOption";
import { set, zip } from "lodash";

export default function Inbox(props) {
  const [leads, setLeads] = useState(props.leads);
  const [sortBy, setSortBy] = useState("time");
  const [displayLead, setDisplayLead] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [displayAddLead, setDisplayAddLead] = useState(false);
  const [search, setSearch] = useState("");
  const [reload, setReload] = useState(false);
  const [zipcodes, setZipcodes] = useState([]);
  const [view5Days, setView5Days] = useState(true);
  const [leadProfileOpenPage, setLeadProfileOpenPage] = useState("");
  const [regions, setRegions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    name: "Open",
    value: "open",
  });
  const firstLoad = useRef(true);
  const db = firebase.firestore();

  function extractUrl(inputString) {
    // Regular expression to find URLs
    const urlRegex = /https?:\/\/[^\s$.?#].[^\s]*/g;
    // Match URLs in the input string
    const urls = inputString.match(urlRegex);
    // Return the first URL found, or null if no URL is found
    return urls ? urls[0] : null;
  }

  useEffect(() => {
    handleSort(sortBy);
    firstLoad.current = false;
    db.collection("Jared")
      .doc("Pooli")
      .collection("Zipcodes")
      .onSnapshot((snapshot) => {
        setZipcodes(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      });

    db.collection("Jared")
      .doc("Pooli")
      .collection("Regions")
      .onSnapshot((snapshot) => {
        setRegions(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });
  }, []);

  useEffect(() => {
    if (!displayLead && !firstLoad.current) {
      fetchLeads();
    }
  }, [displayLead]);

  const fetchLeads = async () => {
    const now = new Date();

    const snapshot = await db
      .collection("Jared")
      .doc("Pooli")
      .collection("Leads")
      .get();

    const leadsData = [];

    for (let index = 0; index < snapshot.docs.length; index++) {
      const doc = snapshot.docs[index];
      const docData = doc.data();
      const docId = doc.id;

      if (docData.source === "Direct" || docData.source === "direct") {
        leadsData.push(docData);
      } else {
        const leadsSnapshot = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(docId)
          .collection("Leads")
          .get();

        for (let index = 0; index < leadsSnapshot.docs.length; index++) {
          const leadDoc = leadsSnapshot.docs[index];
          const leadData = leadDoc.data();
          leadsData.push(leadData);
        }
      }
    }

    setLeads(leadsData);
  };

  const handleSort = (sortBy, leadsData) => {
    setSortBy(sortBy);
    const leadDataToSort = leadsData || leads;
    const sortedLeads = [...leadDataToSort].sort((a, b) => {
      // Function to handle null or undefined values
      const nullsLastComparator = (x, y) => {
        if (x == null) return 1; // null or undefined values go last
        if (y == null) return -1; // non-null values go first
        return 0; // both values are non-null
      };

      switch (sortBy) {
        case "price":
          return (b.price || 0) - (a.price || 0);
        case "customer":
          const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
          const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
          return (
            nullsLastComparator(nameA, nameB) || nameA.localeCompare(nameB)
          );
        case "source":
          return (
            nullsLastComparator(a.source, b.source) ||
            a.source.localeCompare(b.source)
          );
        case "email":
          return (
            nullsLastComparator(a.leadEmail, b.leadEmail) ||
            a.leadEmail.localeCompare(b.leadEmail)
          );
        case "phone":
          return (
            nullsLastComparator(a.phone, b.phone) ||
            a.phone.localeCompare(b.phone)
          );
        case "address":
          return (
            nullsLastComparator(a.address, b.address) ||
            a.address.localeCompare(b.address)
          );
        default:
          return 0;
      }
    });

    // Update the state with the sorted leads
    setLeads(sortedLeads);
    setReload(!reload);
  };

  const handleViewLead = (lead, type) => {
    setSelectedLead(lead);
    setDisplayLead(true);
    if (type) {
      setLeadProfileOpenPage(type);
    }
  };

  const handleMarkLeadClosed = (lead) => {
    if (lead.source === "Direct") {
      // Delete direct lead
      console.log("Marking lead closed", lead);
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(
          lead.leadType === "email"
            ? lead.leadEmail
            : lead.phone.includes("+1")
            ? lead.phone
            : "+1" + lead.phone
        )
        .update({ status: "done" });
    } else {
      // Delete service lead
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(lead.source)
        .collection("Leads")
        .doc(lead.leadId)
        .update({ status: "done" });
    }

    // update lead state locally

    setLeads(
      leads.map((l) => {
        if (l.leadId === lead.leadId) {
          return {
            ...l,
            status: "done",
          };
        }
        return l;
      })
    );

    fetchLeads();
  };

  const handleMarkLeadOpen = (lead) => {
    // Proceed with deletion if the user confirmed
    if (lead.source === "Direct") {
      // Delete direct lead
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(
          lead.leadType === "email"
            ? lead.leadEmail
            : lead.phone.includes("+1")
            ? lead.phone
            : "+1" + lead.phone
        )
        .update({ status: "open" });
    } else {
      // Delete service lead
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(lead.source)
        .collection("Leads")
        .doc(lead.leadId)
        .update({ status: "open" });
    }

    setLeads(
      leads.map((l) => {
        if (l.leadId === lead.leadId) {
          return {
            ...l,
            status: "open",
          };
        }
        return l;
      })
    );
  };

  const getRegionPrice = (zipcode) => {
    let zipcodeData = zipcodes.find((a) => a.zipcode === zipcode);
    let regionId = zipcodeData?.region?.id;
    let regionData = regions.find((a) => a.id === regionId);
    console.log(regionData);
    return regionData?.basePrice || 0;
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="flex flex-row items-center">
          <h1 className="text-xl font-semibold text-gray-900">Inbox</h1>
        </div>
        <div className="ml-auto flex">
          <div
            style={{
              width: 220,
              height: 38,
              marginTop: -2,
              marginRight: 8,
            }}
          >
            <SelectOption
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>

          <input
            placeholder="Enter search term..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              border: "1px solid #D1D5DB",
              borderRadius: 5,
              width: 220,
              paddingLeft: 5,
              height: 38,
              color: "black",
              marginTop: 2,
            }}
          />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 flex"
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Lead Source</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Region</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Price</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Message</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex"></div>
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    ></th>
                  </tr>
                </thead>

                <tbody
                  key={reload}
                  className="divide-y divide-gray-200 bg-white"
                >
                  {leads
                    .filter((a) =>
                      search.length === 0
                        ? true
                        : (a.firstName + " " + a.lastName)
                            .toLowerCase()
                            .includes(search.toLowerCase())
                    )
                    .filter((a) => {
                      if (selectedOption.value === "done") {
                        return a.status === "done";
                      } else if (selectedOption.value === "open") {
                        return a.status === "open" || a.status === undefined;
                      }
                    })
                    .sort((a, b) => {
                      const aEmailTime =
                        a.emails?.[a.emails.length - 1]?.time ?? 0;
                      const aTextTime =
                        a.texts?.[a.texts.length - 1]?.time ?? 0;
                      const bEmailTime =
                        b.emails?.[b.emails.length - 1]?.time ?? 0;
                      const bTextTime =
                        b.texts?.[b.texts.length - 1]?.time ?? 0;

                      const aLatestTime = Math.max(aEmailTime, aTextTime);
                      const bLatestTime = Math.max(bEmailTime, bTextTime);

                      // Multiply by 1000 to convert to milliseconds for proper time comparison
                      return bLatestTime * 1000 - aLatestTime * 1000;
                    })
                    .map((lead, index) => (
                      <tr key={lead.leadEmail + index}>
                        <td className="whitespace py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-left">
                          {lead.firstName + " " + lead.lastName}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                          {lead.source}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                          {zipcodes.find((a) => a.zipcode === lead.zipcode)
                            ? zipcodes.find((a) => a.zipcode === lead.zipcode)
                                .region.name
                            : "None"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                          {getRegionPrice(lead.zipcode)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left w-48">
                          {(() => {
                            // Safely attempt to sort emails and texts if they exist and are not empty
                            const newestEmail =
                              lead.emails && lead.emails.length > 0
                                ? lead.emails.sort((a, b) => b.time - a.time)[0]
                                : null;
                            const newestText =
                              lead.texts && lead.texts.length > 0
                                ? lead.texts.sort((a, b) => b.time - a.time)[0]
                                : null;

                            // Determine which is newer based on the time property
                            const newestMessage =
                              newestEmail &&
                              (!newestText ||
                                newestEmail.time > newestText.time)
                                ? newestEmail
                                : newestText;

                            // Check if newestMessage is not null, then check if it's an email or text and display the correct content
                            const messageContent = newestMessage
                              ? newestMessage.text || newestMessage.body
                              : "";

                            // Return the message, if it exists, trimmed to 100 characters
                            return messageContent
                              ? `${messageContent.substring(0, 50)}...`
                              : "";
                          })()}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pr-4 text-right text-sm font-medium sm:pr-6">
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "20%",
                              justifyContent: "space-around",
                            }}
                          >
                            <div
                              style={{
                                width: 100,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                onClick={() => handleViewLead(lead, "email")}
                                style={{
                                  color:
                                    lead.emails?.length > 0
                                      ? "#32CD32"
                                      : "grey",
                                  display:
                                    lead.leadEmail?.length > 0
                                      ? "flex"
                                      : "none",
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                              >
                                Email
                                {lead.emails?.some(
                                  (a) => a.viewed === false
                                ) && (
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "0px",
                                      right: "-10px",
                                      height: "8px",
                                      width: "8px",
                                      borderRadius: "50%",
                                      backgroundColor: "red",
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                width: 100,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                onClick={() => handleViewLead(lead, "sms")}
                                style={{
                                  color:
                                    lead.texts?.length > 0 ? "#32CD32" : "grey",
                                  display:
                                    lead.phone?.length > 0 ? "flex" : "none",
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                              >
                                Text
                                {lead.texts?.some(
                                  (a) => a.viewed === false
                                ) && (
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "0px",
                                      right: "-10px",
                                      height: "8px",
                                      width: "8px",
                                      borderRadius: "50%",
                                      backgroundColor: "red",
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                width: 120,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {lead.actionUrl && (
                                <div
                                  onClick={() =>
                                    window.open(
                                      extractUrl(lead.actionUrl),
                                      "_blank"
                                    )
                                  }
                                  style={{
                                    color: lead.source.includes("Angi")
                                      ? "#FF6153"
                                      : lead.source.includes("Google")
                                      ? "#1B72E8"
                                      : "white",
                                    position: "relative",
                                    cursor: "pointer",
                                    backgroundColor: lead.source.includes(
                                      "Google"
                                    )
                                      ? "#F8F9FA"
                                      : lead.source.includes("Yelp")
                                      ? "red"
                                      : lead.source.includes("Thumb")
                                      ? "#36A7FF"
                                      : lead.source.includes("Angi")
                                      ? "#EDF0F1"
                                      : "#202937",
                                    width: 80,
                                    height: 30,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 5,
                                    marginRight: 12,
                                    fontSize: 11,
                                  }}
                                >
                                  {lead.source.split(" ")[0]}
                                </div>
                              )}
                            </div>
                            <div
                              style={{
                                width: 100,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                onClick={() => handleViewLead(lead)}
                                className="text-[#202937] cursor-pointer"
                              >
                                View
                              </div>
                              {lead.status !== "done" && (
                                <div
                                  onClick={() => handleMarkLeadClosed(lead)}
                                  style={{ marginLeft: 80 }}
                                  className="text-[#202937] cursor-pointer"
                                >
                                  <FiCheck size={20} />
                                </div>
                              )}
                              {lead.status === "done" && (
                                <div
                                  onClick={() => handleMarkLeadOpen(lead)}
                                  style={{ marginLeft: 40 }}
                                  className="text-[#202937] cursor-pointer"
                                >
                                  <FiRepeat size={20} />
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <LeadProfile
        leadData={selectedLead}
        open={displayLead}
        setOpen={setDisplayLead}
        zipcodes={zipcodes}
        leadProfileOpenPage={leadProfileOpenPage}
      />
      <AddLead open={displayAddLead} setOpen={setDisplayAddLead} />
    </div>
  );
}
