import React, { useEffect, useState } from "react";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import Modal from "./Modal";
import firebase from "firebase/compat/app";

const ServiceCard = ({
  serviceName,
  onEnrolledChange,
  enrolledItems,
  leadData,
  setLeadData,
  zipcodes,
}) => {
  const db = firebase.firestore();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isActivelyEnrolled, setIsActivelyEnrolled] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [formData, setFormData] = useState({
    price: zipcodes.find((a) => a.zipcode === leadData.zipcode)
      ? zipcodes.find((a) => a.zipcode === leadData.zipcode).price
      : "0",
    weekStart: "", // This will handle a date string
    dealsGiven: "",
    discountCode: "",
    notes: "",
    enroll: false, // This remains a checkbox
  });

  const toggleExpansion = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (formData) {
      onEnrolledChange(serviceName, formData.enroll, formData);
    }
  }, [formData]);

  useEffect(() => {
    if (leadData && leadData.services) {
      const serviceData = leadData.services.find((a) =>
        Object.keys(a).includes(serviceName)
      );

      if (serviceData && serviceData[serviceName]) {
        // get the zipcode price and add it for the price if the serviceName is Weekly Pool Care

        const newFormData = {
          price: serviceData[serviceName].price || "",
          weekStart: serviceData[serviceName].weekStart || "",
          dealsGiven: serviceData[serviceName].dealsGiven || "",
          discountCode: serviceData[serviceName].discountCode || "",
          notes: serviceData[serviceName].notes || "",
          enroll: serviceData[serviceName].enroll || false,
        };

        setFormData(newFormData);
        setIsActivelyEnrolled(true);
        console.log("enrolled");
      } else {
        console.log("not enrolled");
      }
    }
  }, [leadData, serviceName]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmitCancellation = async (reason, comment, lead) => {
    setLeadData(
      lead.services.filter((service) => Object.keys(service)[0] !== serviceName)
    );
    setFormData({
      price: "",
      weekStart: "",
      dealsGiven: "",
      discountCode: "",
      notes: "",
      enroll: false,
    });

    if (leadData.source.toLowerCase() === "direct") {
      let leadType = "";

      if (leadData.leadEmail && leadData.leadEmail.length > 0) {
        const leadEmailRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.leadEmail)
          .get();
        if (leadEmailRef.exists) {
          leadType = "email";
        }
      }

      if (leadData.phone && leadData.phone.length > 0) {
        const leadTextRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.phone)
          .get();
        if (leadTextRef.exists) {
          leadType = "text";
        }
      }

      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(leadType === "email" ? leadData.leadEmail : leadData.phone)
        .update({
          services: lead.services.filter(
            (service) => Object.keys(service)[0] !== serviceName
          ),
        });
    } else {
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(leadData.source)
        .collection("Leads")
        .doc(leadData.leadId)
        .update({
          services: lead.services.filter(
            (service) => Object.keys(service)[0] !== serviceName
          ),
        });
    }

    setIsActivelyEnrolled(false);
    setShowCancelModal(false);
  };

  return (
    <div
      style={{
        border: `1px solid ${
          enrolledItems.has(serviceName) || isActivelyEnrolled
            ? "green"
            : "black"
        }`,
        maxHeight: isExpanded ? "600px" : "50px",
        overflow: "hidden",
        width: "100%",
        transition: "max-height 0.5s ease", // Adjust timing as needed
        padding: 6,
        marginTop: 10,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: isExpanded ? "flex-start" : "center",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginTop: isExpanded ? 4 : 0,
          paddingLeft: isExpanded ? 6 : 0,
        }}
      >
        <p>{serviceName}</p>
        <span className="cursor-pointer" onClick={toggleExpansion}>
          {isExpanded ? <FiArrowUp size={20} /> : <FiArrowDown size={20} />}
        </span>
      </div>
      {isExpanded && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 10,
            padding: "10px",
          }}
        >
          {Object.entries(formData).map(([key, value]) =>
            key !== "enroll" ? (
              <label key={key} style={{ marginBottom: "10px" }}>
                {key.charAt(0).toUpperCase() +
                  key
                    .slice(1)
                    .replace(/([A-Z])/g, " $1")
                    .trim()}
                :
                <input
                  type={key === "weekStart" ? "date" : "text"}
                  name={key}
                  value={value}
                  disabled={isActivelyEnrolled}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    margin: "5px 0",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </label>
            ) : (
              <label
                style={{ display: isActivelyEnrolled ? "none" : "initial" }}
                key={key}
                className="mt-2"
              >
                Enroll:
                <input
                  type="checkbox"
                  name={key}
                  checked={value}
                  onChange={handleChange}
                  style={{ margin: "0 10px" }}
                />
              </label>
            )
          )}
          {isActivelyEnrolled && (
            <div
              onClick={() => setShowCancelModal(true)}
              style={{ marginLeft: "auto", marginTop: 10, cursor: "pointer" }}
            >
              Cancel
            </div>
          )}
        </div>
      )}
      {showCancelModal && (
        <Modal
          handleSubmitCancellation={handleSubmitCancellation}
          setShowCancelModal={setShowCancelModal}
          leadData={leadData}
        />
      )}
    </div>
  );
};

export default ServiceCard;
