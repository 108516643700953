import logo from "./logo.svg";
import "./App.css";
import Dashboard from "./Dashboard";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD70kJKBJl83GEAa5wP16UdE-nmnKJNC4E",
  authDomain: "symbri-production.firebaseapp.com",
  projectId: "symbri-production",
  storageBucket: "symbri-production.appspot.com",
  messagingSenderId: "73831026776",
  appId: "1:73831026776:web:92b86496a171d0e6927a2d",
  measurementId: "G-MRRNQV0QM6",
};

firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <div className="App">
      <Dashboard />
    </div>
  );
}

export default App;
