import React, { useState } from "react";

const Modal = ({ setShowCancelModal, handleSubmitCancellation, leadData }) => {
  const [reason, setReason] = useState("");
  const [comments, setComments] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const cancel = () => {
    // gotta select a reason
    if (reason.length === 0) {
      alert("Please select a reason for cancellation");
      return;
    }
    handleSubmitCancellation(reason, comments, leadData);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "1000",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          width: "340px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2>Reason for Cancellation</h2>
        <select
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          style={{
            marginBottom: "10px",
            padding: "8px",
            borderRadius: "4px",
            borderColor: "#ccc",
            borderWidth: 1,
            backgroundColor: "#f8f8f8",
            boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
          }}
        >
          <option value="">Select Reason</option>
          <option value="Price too high">Price too high</option>
          <option value="Unhappy with service">Unhappy with service</option>
          <option value="Seasonality">Seasonality</option>
          <option value="Other">Other</option>
        </select>
        <textarea
          placeholder="Additional comments"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          style={{
            height: "100px",
            marginBottom: "10px",
            padding: "8px",
            borderRadius: "4px",
            borderColor: "#ccc",
            borderWidth: 1,
            resize: "none",
            boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.06)",
          }}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <button
            onClick={cancel}
            style={{
              padding: "10px 20px",
              backgroundColor: "#202937",
              color: "white",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
            }}
          >
            Submit
          </button>
          <button
            onClick={() => setShowCancelModal(false)}
            style={{
              padding: "10px 20px",
              backgroundColor: "#202937",
              color: "white",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
              marginTop: 10,
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
