import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import { set } from "lodash";

function generateRandom12DigitId() {
  // Generate a random number and remove the '0.' prefix
  let randomPart = Math.random().toString().slice(2, 8);
  // Use the current time to get the rest of the digits, ensuring some level of uniqueness
  let timePart = Date.now().toString().slice(-4);
  // Combine both parts to get a 12-digit string
  return randomPart + timePart;
}

export default function AddLead(props) {
  const db = firebase.firestore();
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pets, setPets] = useState("");
  const [gateCode, setGateCode] = useState("");
  const [ingroundAboveGround, setIngroundAboveGround] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpen(props.open);

    if (props.open) {
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };

  const addLead = async () => {
    const leadId = generateRandom12DigitId();
    // make first name last name and address required fields

    // make fetch request

    if (
      firstName === "" ||
      lastName === "" ||
      address === "" ||
      zipcode === "" ||
      phone === ""
    ) {
      alert(
        "First Name, Last Name, Zipcode, Phone, and Address, are required fields."
      );
    } else {
      setLoading(true);
      const response = await fetch(
        "https://us-central1-symbri-production.cloudfunctions.net/checkLeadExists",
        {
          method: "POST",
          body: JSON.stringify({
            finalObject: {
              time: dayjs().unix(),
              formattedTime: dayjs().format("MM/DD/YYYY"),
              firstName: firstName,
              lastName: lastName,
              leadEmail: email,
              phone: phone.includes("+1") ? phone : "+1" + phone,
              address: address,
              category: "lead",
              source: "Direct",
              leadId: leadId,
              emails: [],
              pets: pets,
              gateCode: gateCode,
              ingroundAboveGround: ingroundAboveGround,
              zipcode: zipcode,
            },
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.match) {
          alert("Lead already exists");
          setLoading(false);
          return;
        }
      }

      await db
        .collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(phone.includes("+1") ? phone : "+1" + phone)
        .set({
          time: dayjs().unix(),
          formattedTime: dayjs().format("MM/DD/YYYY"),
          firstName: firstName,
          lastName: lastName,
          leadEmail: email,
          phone: phone.includes("+1") ? phone : "+1" + phone,
          address: address,
          category: "lead",
          source: "Direct",
          leadId: leadId,
          emails: [],
          pets: pets,
          gateCode: gateCode,
          ingroundAboveGround: ingroundAboveGround,
          zipcode: zipcode,
          leadType: "sms",
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
      setLoading(false);
      // add lead to local state
      props.setLeads(
        props.leads.concat({
          time: dayjs().unix(),
          formattedTime: dayjs().format("MM/DD/YYYY"),
          firstName: firstName,
          lastName: lastName,
          leadEmail: email,
          phone: phone.includes("+1") ? phone : "+1" + phone,
          address: address,
          category: "lead",
          source: "Direct",
          leadId: leadId,
          emails: [],
          pets: pets,
          gateCode: gateCode,
          ingroundAboveGround: ingroundAboveGround,
          zipcode: zipcode,
          leadType: "sms",
        })
      );
      handleClose();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-[#202937] py-6 px-4 sm:px-6 border border-white">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Add Lead
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XCircleIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Add a new lead to the database here.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                First Name
                              </label>
                              <div className="mt-1">
                                <input
                                  style={{ borderRadius: 5 }}
                                  id="leadName"
                                  className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Last Name
                              </label>
                              <div className="mt-1">
                                <input
                                  style={{ borderRadius: 5 }}
                                  id="leadName"
                                  className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Street Address
                              </label>
                              <div className="mt-1">
                                <input
                                  style={{ borderRadius: 5 }}
                                  id="leadName"
                                  className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Zipcode
                              </label>
                              <div className="mt-1">
                                <input
                                  style={{ borderRadius: 5 }}
                                  id="leadName"
                                  className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  value={zipcode}
                                  onChange={(e) => setZipcode(e.target.value)}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Email
                              </label>
                              <div className="mt-1">
                                <input
                                  style={{ borderRadius: 5 }}
                                  id="leadName"
                                  className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Phone
                              </label>

                              <div className="mt-1">
                                <input
                                  style={{ borderRadius: 5 }}
                                  id="leadName"
                                  className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Gate Code
                              </label>

                              <div className="mt-1">
                                <input
                                  id="leadName"
                                  className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  value={gateCode}
                                  onChange={(e) => setGateCode(e.target.value)}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Inground / Above Ground
                              </label>

                              <div className="mt-1">
                                <input
                                  id="leadName"
                                  className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  value={ingroundAboveGround}
                                  onChange={(e) =>
                                    setIngroundAboveGround(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Aggressive Pets
                              </label>

                              <div className="mt-1">
                                <select
                                  id="pets"
                                  className="block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  value={pets}
                                  onChange={(e) => setPets(e.target.value)}
                                >
                                  <option value="none">Select an option</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        disabled={loading}
                        className="rounded-md border border-gray-300 bg-[#202937] py-2 px-4 text-sm font-medium text-white shadow-sm mr-2"
                        onClick={() => addLead()}
                      >
                        {loading ? "Loading..." : "Add"}
                      </button>
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
