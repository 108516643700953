import { PieChart } from "@mui/x-charts/PieChart";
import React, { useEffect, useState } from "react";

export default function Stats(props) {
  const [stats, setStats] = useState(props.stats);
  const [reload, setReload] = useState(false);

  const displayStats = [
    { name: "Daily Leads", stat: stats.daily },
    { name: "Weekly Leads", stat: stats.weekly },
    { name: "Monthly Leads", stat: stats.monthly },
  ];

  useEffect(() => {
    setStats(props.stats);
    setReload(!reload);
  }, [props.reloadChildComponents]);

  const handleApiLoaded = (map, maps) => {};

  return (
    <div className="flex flex-col">
      <div
        id="map-container"
        style={{
          width: "100vw",
          height: 4,
          alignSelf: "center",
        }}
      ></div>

      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3 mt-10">
        {displayStats.map((item) => (
          <div
            key={item.name}
            className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
          >
            <dt className="truncate text-sm font-medium text-black">
              {item.name}
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-black">
              {item.stat}
            </dd>
          </div>
        ))}
      </dl>

      <div className="mt-5 flex flex-col items-center justify-center overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 max-w-[32.4%]">
        <h3 className="text-base font-semibold leading-6">Leads by Source</h3>
        <div style={{ color: "white" }} className="mt-4">
          <PieChart
            series={[
              {
                data: stats.sources,
              },
            ]}
            width={800}
            height={300}
          />
        </div>
      </div>
    </div>
  );
}
