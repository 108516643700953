import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Letter } from "react-letter";
import { extract } from "letterparser";
import { FiSend, FiXCircle } from "react-icons/fi";
import { stateToHTML } from "draft-js-export-html";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import { stripHtml } from "string-strip-html";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { Circles } from "react-loader-spinner";

const ViewEmail = ({
  open,
  setOpen,
  emailData,
  leadData,
  originalMessageId,
  emailSubject,
  emails,
}) => {
  const [editorState, setEditorState] = useState("");
  const [currentEmailData, setCurrentEmailData] = useState(emailData);
  const [allEmails, setAllEmails] = useState(emails);
  const [generatingReply, setGeneratingReply] = useState(false);
  const db = firebase.firestore();

  useEffect(() => {
    console.log(emails);
    setAllEmails(emails);
  }, [emails]);

  function removeHtmlFromString(inputString) {
    // Use the stripHtml function to remove HTML content
    const cleanedString = stripHtml(inputString).result; // Get the cleaned result

    return cleanedString;
  }

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
    setCurrentEmailData(emailData);
  }, [emailData]);

  const sendEmail = async () => {
    const htmlContent = stateToHTML(editorState.getCurrentContent());

    const token = await firebase.auth().currentUser.getIdToken();

    setEditorState("");
    // add new email into current emails state
    setAllEmails((prev) => [
      ...prev,
      {
        from: "swim@pooli.com",
        to: leadData.leadEmail,
        html: htmlContent,
        text: "Reply from Pooli",
        subject: emails[0]?.subject || "New Email From Poolie!",
        time: dayjs().unix(),
        messageId: emails[emails.length - 1]?.messageId || "",
        references: emails[emails.length - 1]?.threadIds || "",
      },
    ]);

    try {
      const res = await fetch(
        "https://us-central1-symbri-production.cloudfunctions.net/jaredSendEmail",
        {
          method: "POST",
          body: JSON.stringify({
            emailData: {
              html: htmlContent,
              body: removeHtmlFromString(htmlContent),
              subject:
                emails[emails.length - 1]?.subject || "New Email From Poolie!",
              to: leadData.leadEmail,
              messageId: emails[emails.length - 1]?.messageId || "",
              references: emails[emails.length - 1]?.references || "",
            },
            token: token,
            type: "reply",
          }),
        }
      );

      if (res.ok) {
        const sentEmailData = await res.json();

        console.log(sentEmailData);

        const messageId = sentEmailData.info.messageId;

        const references = sentEmailData.info.references;

        if (leadData.source.toLowerCase() === "direct") {
          console.log({
            from: "swim@pooli.com",
            to: leadData.leadEmail,
            html: htmlContent,
            text: "Reply from Pooli",
            subject: emails[0]?.subject || "New Email From Poolie!",
            time: dayjs().unix(),
            messageId: messageId || "",
            references: references || "",
          });
          db.collection("Jared")
            .doc("Pooli")
            .collection("Leads")
            .doc(
              leadData.leadType === "email"
                ? leadData.leadEmail
                : leadData.phone
            )
            .update({
              emails: firebase.firestore.FieldValue.arrayUnion({
                from: "swim@pooli.com",
                to: leadData.leadEmail,
                html: htmlContent,
                text: "Reply from Pooli",
                subject: emails[0]?.subject || "New Email From Poolie!",
                time: dayjs().unix(),
                messageId: messageId || "",
                references: references || "",
              }),
            });
        } else {
          db.collection("Jared")
            .doc("Pooli")
            .collection("Leads")
            .doc(leadData.source)
            .collection("Leads")
            .doc(leadData.leadId)
            .update({
              emails: firebase.firestore.FieldValue.arrayUnion({
                from: "swim@pooli.com",
                to: leadData.leadEmail,
                html: htmlContent,
                text: "Reply from Pooli",
                subject: emails[0]?.subject || "New Email From Poolie!",
                time: dayjs().unix(),
                messageId: messageId || "",
                references: references || "",
              }),
            });
        }
      } else {
        throw new Error("Error sending email!");
      }
    } catch (error) {
      console.log(error);
      alert("Error sending email!");
      return;
    }
  };

  const handleGetAiReply = async () => {
    const token = await firebase.auth().currentUser.getIdToken();

    setGeneratingReply(true);

    let strippedEmails = emails.slice(-6).map((email) => {
      return {
        from: email.from,
        to: email.to,
        html: removeHtmlFromString(email.html), // Ensure you have a function defined to strip HTML
        time: email.time,
      };
    });

    try {
      const res = await fetch(
        "https://us-central1-symbri-production.cloudfunctions.net/replyGenerate",
        {
          method: "POST",
          body: JSON.stringify({
            emails: strippedEmails,
            type: "email",
          }),
        }
      );

      const data = await res.json();
      console.log(data);
      if (data.message === "Success") {
        const content = ContentState.createFromText(data.response);
        const editState = EditorState.createWithContent(content);
        setEditorState(editState);
        setGeneratingReply(false);
      } else {
        alert("Error generating reply!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: open ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          alignItems: "center",
          padding: 14,
          height: 900,
          width: 800,
          borderRadius: 5,
        }}
      >
        <div
          onClick={() => setOpen(false)}
          style={{ marginLeft: "auto", cursor: "pointer" }}
        >
          <FiXCircle size={22} />
        </div>

        <div
          style={{
            height: "80%",
            overflowY: "scroll",
            paddingBottom: 100,
            width: "100%",
          }}
        >
          {allEmails
            .sort((a, b) => dayjs(a.dateTime) > dayjs(b.dateTime))
            .map((email, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid black",
                    padding: "10px",
                    alignItems: "baseline", // Keeps text aligned along the baseline
                    minHeight: 100,
                  }}
                >
                  {/* Left Section */}
                  <div style={{ flex: 1 }}>
                    {/* Sender and Content, organized in a column */}
                    <div>{email.from}</div>
                    <div style={{ marginTop: 10 }}>
                      <Letter html={removeHtmlFromString(email.html)} />
                    </div>
                  </div>

                  {/* Right Section */}
                  <div style={{ flexShrink: 0 }}>
                    <div>
                      {dayjs.unix(email.time).format("MMMM D, YYYY h:mm A")}
                    </div>
                    {/* Date and time on the top right */}
                  </div>
                </div>
              );
            })}
        </div>

        <div
          style={{
            border: "1px solid black",
            borderRadius: 5,
            display: leadData.leadEmail
              ? leadData.leadEmail.length > 0
                ? "initial"
                : "none"
              : "none",
          }}
        >
          <Editor
            editorState={editorState}
            onEditorStateChange={(editorState) => setEditorState(editorState)}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorStyle={{ height: 160, padding: 10, maxWidth: 740 }}
          />
          <div className="flex">
            <div
              onClick={sendEmail}
              style={{
                height: 35,
                width: 100,
                border: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#202937",
                borderRadius: 5,
                marginLeft: "auto",
                marginRight: 10,
                marginBottom: 10,
                cursor: "pointer",
              }}
            >
              <div style={{ color: "white", fontSize: 14 }}>Send</div>
            </div>
            {emails[0] && (
              <div
                onClick={generatingReply ? null : handleGetAiReply}
                style={{
                  height: 35,
                  width: 50,
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#202937",
                  borderRadius: 5,
                  marginRight: 10,
                  marginBottom: 10,
                  cursor: "pointer",
                }}
              >
                {generatingReply && (
                  <div style={{ color: "white", fontSize: 14 }}>
                    <Circles
                      height="24"
                      width="24"
                      color="white"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                )}
                {!generatingReply && (
                  <div style={{ color: "white", fontSize: 14 }}>
                    <FaWandMagicSparkles />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEmail;
