import React, { useState, useEffect, useRef } from "react";
import { FiCheckCircle, FiTrash } from "react-icons/fi";
import dayjs from "dayjs";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import firebase from "firebase/compat/app";
import ManageRegions from "./ManageRegions";
import AddZip from "./AddZip";
import EditZip from "./EditZip";
import SelectRegion from "./SelectRegion";

export default function Regions(props) {
  const [regions, setRegions] = useState([]);
  const [parentRegions, setParentRegions] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);
  const [displayManageRegions, setDisplayManageRegions] = useState(false);
  const [search, setSearch] = useState("");
  const [reload, setReload] = useState(false);
  const [displayAddZip, setDisplayAddZip] = useState(false);
  const [displayEditZip, setDisplayEditZip] = useState(false);
  const [editZipData, setEditZipData] = useState({});
  const [region, setRegion] = useState({
    name: "None",
    color: "purple",
    id: "0",
  });
  const db = firebase.firestore();

  useEffect(() => {
    db.collection("Jared")
      .doc("Pooli")
      .collection("Regions")
      .onSnapshot((snapshot) => {
        setRegions(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });

    db.collection("Jared")
      .doc("Pooli")
      .collection("ParentRegions")
      .onSnapshot((snapshot) => {
        setParentRegions(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      });

    db.collection("Jared")
      .doc("Pooli")
      .collection("Zipcodes")
      .onSnapshot((snapshot) => {
        setZipcodes(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      });
  }, []);

  const handleDeleteZipcode = (zipcode) => {
    db.collection("Jared")
      .doc("Pooli")
      .collection("Zipcodes")
      .doc(zipcode.id)
      .delete()
      .then(() => {
        setReload(!reload);
      });
  };

  const handleEditZipcode = (zipcode) => {
    setDisplayEditZip(true);
    setEditZipData(zipcode);
  };

  const handleDownloadCsv = () => {
    const csvHeader = "Zipcode,Region,Price\n"; // Define the header of the CSV file
    const csvRows = zipcodes
      .map(
        (zipcode) =>
          `${zipcode.zipcode},${zipcode.region.name},${zipcode.price}`
      )
      .join("\n");

    const csvContent = csvHeader + csvRows;

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "zipcodes.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="flex flex-row items-center">
          <h1 className="text-xl font-semibold text-gray-900">Regions</h1>
        </div>
        <div className="ml-auto flex">
          <input
            placeholder="Search zipcodes..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              border: "2px solid #D1D5DB",
              borderRadius: 5,
              width: 220,
              paddingLeft: 5,
              height: 40,
              color: "black",
              marginTop: 2,
              marginRight: 14,
            }}
          />

          <div
            style={{
              width: 220,
              height: 35,
              color: "black",
              marginTop: -3,
            }}
          >
            <SelectRegion
              setRegion={setRegion}
              regions={regions}
              region={region}
            />
          </div>
          <button
            onClick={() => setDisplayManageRegions(true)}
            style={{
              width: 100,
              height: 40,
              backgroundColor: "#202937",
              borderRadius: 5,
              marginLeft: 14,
            }}
          >
            Regions
          </button>
          <button
            onClick={() => setDisplayAddZip(true)}
            style={{
              width: 100,
              height: 40,
              backgroundColor: "#202937",
              borderRadius: 5,
              marginLeft: 14,
            }}
          >
            Add Zip
          </button>
          <button
            onClick={() => handleDownloadCsv(true)}
            style={{
              width: 100,
              height: 40,
              backgroundColor: "#202937",
              borderRadius: 5,
              marginLeft: 14,
            }}
          >
            Export
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 flex"
                    >
                      Zipcode
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Region</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">Price</div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    ></th>
                  </tr>
                </thead>

                <tbody
                  key={reload}
                  className="divide-y divide-gray-200 bg-white"
                >
                  {zipcodes
                    .filter((a) =>
                      region.name === "None"
                        ? true
                        : a.region.name === region.name
                    )
                    .filter((a) =>
                      search.length > 0 ? a.zipcode.includes(search) : true
                    )
                    .map((zipcode, index) => (
                      <tr key={zipcode.zipcode}>
                        <td className="whitespace py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-left">
                          {zipcode.zipcode}
                        </td>

                        <td
                          style={{
                            color: regions.find(
                              (a) => a.id === zipcode.region.id
                            )
                              ? regions.find((a) => a.id === zipcode.region.id)
                                  .color
                              : "purple",
                          }}
                          className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left"
                        >
                          {zipcode.region.name}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">
                          ${zipcode.price}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left"></td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left"></td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left"></td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                          <div
                            onClick={() => handleEditZipcode(zipcode)}
                            style={{ marginLeft: 20, cursor: "pointer" }}
                          >
                            View
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-6 text-black">
                          <div
                            style={{ marginLeft: 20 }}
                            onClick={() => handleDeleteZipcode(zipcode)}
                            className="text-[#202937] cursor-pointer"
                          >
                            <FiTrash size={20} />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ManageRegions
        setOpen={setDisplayManageRegions}
        open={displayManageRegions}
        regions={regions}
        parentRegions={parentRegions}
      />
      <AddZip
        setOpen={setDisplayAddZip}
        open={displayAddZip}
        regions={regions}
      />
      <EditZip
        setOpen={setDisplayEditZip}
        open={displayEditZip}
        regions={regions}
        zipcode={editZipData.zipcode}
        region={editZipData.region}
        price={editZipData.price}
        manager={editZipData.manager}
      />
    </div>
  );
}
