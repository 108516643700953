import React, { useRef, useEffect, useState } from "react";
import dayjs from "dayjs"; // Ensure dayjs is imported
import { FaWandMagicSparkles } from "react-icons/fa6";
import { Circles } from "react-loader-spinner";

const SMS = ({ texts, textMessage, setTextMessage, leadData, phones }) => {
  const messageContainerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [generatingReply, setGeneratingReply] = useState(false);

  const formatPhoneNumber = (phoneNumber) => {
    const digitsOnly = phoneNumber.replace(/\D/g, "");

    if (
      (digitsOnly.startsWith("1") && digitsOnly.length === 11) ||
      digitsOnly.length === 10
    ) {
      const formattedNumber = "+1" + digitsOnly.slice(-10);
      return formattedNumber;
    } else {
      return 0;
    }
  };

  const handleSendText = async () => {
    if (leadData.phone.length < 10) {
      alert("Invalid phone number");
      return;
    }
    setLoading(true);
    const formattedNumber = formatPhoneNumber(leadData.phone);

    if (formattedNumber === 0) {
      alert("Invalid phone number");
      return;
    }

    setMessages((prev) => [
      ...prev,
      {
        body: textMessage,
        from: "You",
        time: Math.floor(Date.now() / 1000),
        viewed: true,
      },
    ]);

    const response = await fetch(
      "https://us-central1-symbri-production.cloudfunctions.net/jaredSendText",
      {
        method: "POST",
        body: JSON.stringify({
          body: textMessage,
          to: formattedNumber,
          leadData: leadData,
        }),
      }
    );

    setTextMessage("");
    setLoading(false);

    console.log(await response.json());
    scrollToBottom();
  };

  const scrollToBottom = () => {
    const scroll = messageContainerRef.current;
    if (scroll) {
      scroll.scrollTop = scroll.scrollHeight;
    }
  };

  useEffect(() => {
    setMessages(texts);
    scrollToBottom();
  }, [texts]);

  const handleGetAiReply = async () => {
    setGeneratingReply(true);

    try {
      const res = await fetch(
        "https://us-central1-symbri-production.cloudfunctions.net/replyGenerate",
        {
          method: "POST",
          body: JSON.stringify({
            emails: JSON.stringify(texts),
            type: "sms",
          }),
        }
      );

      const data = await res.json();
      console.log(data);
      if (data.message === "Success") {
        setTextMessage(data.response);
        setGeneratingReply(false);
      } else {
        alert("Error generating reply!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col flex-1 mt-1 bg-gray-100">
      <div
        ref={messageContainerRef}
        className="flex-1 overflow-auto p-4"
        style={{
          fontFamily: "sans-serif",
          minHeight: 500,
          maxHeight: 500,
        }}
      >
        {texts
          .sort((a, b) => a.time - b.time)
          .map((text, index) => (
            <div
              key={text.time}
              className={`my-2 p-3 rounded-lg ${
                text.from === "You" ? "bg-blue-200 ml-auto" : "bg-white"
              } max-w-[98%]`}
              style={{
                boxShadow: "0 1px 2px rgba(0,0,0,0.15)",
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                borderWidth: text.viewed === false ? 1 : 0,
                borderColor: text.viewed === false ? "red" : "transparent",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <div className="text-xs text-gray-800">
                  {phones.includes(text.from)
                    ? "From: Jared"
                    : `From: ${text.from}`}
                </div>
                <div className="text-xs text-right text-gray-500">
                  {dayjs(text.time * 1000).format("MMMM D, YYYY h:mm A")}
                </div>
              </div>
              <div className="text-sm">{text.body}</div>
              {text.mms && (
                <img
                  src={text.mms}
                  alt="MMS"
                  className="mt-2 rounded-lg"
                  style={{
                    maxWidth: 200,
                    maxHeight: 200,
                    objectFit: "cover",
                  }}
                />
              )}
            </div>
          ))}
      </div>
      <div className="p-4 bg-gray-100">
        <div className="flex flex-col">
          <textarea
            type="text"
            name="message"
            id="message"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md pl-2 h-40"
            placeholder="Type a message..."
            value={textMessage}
            onChange={(e) => setTextMessage(e.target.value)}
          />
          <button
            disabled={loading}
            onClick={handleSendText}
            type="button"
            className="inline-flex justify-center py-2 px-4 mt-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {loading ? "Sending..." : "Send"}
          </button>
          {texts.length > 0 && (
            <button
              disabled={generatingReply}
              onClick={handleGetAiReply}
              type="button"
              className="flex items-center justify-center mt-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {generatingReply ? (
                <Circles
                  height="20"
                  width="20"
                  color="white"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                <FaWandMagicSparkles size={20} />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SMS;
