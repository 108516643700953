import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import ViewEmail from "./ViewEmail";
import NewEmail from "./NewEmail";
import SMS from "./SMS";
import ServiceCard from "./ServiceCard";

export default function LeadProfile(props) {
  const db = firebase.firestore();
  const [open, setOpen] = useState(false);
  const [leadData, setLeadData] = useState(props.leadData);
  const [viewEmailOpen, setViewEmailOpen] = useState(false);
  const [newEmailOpen, setNewEmailOpen] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [originalMessageId, setOriginalMessageId] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [emailSubject, setEmailSubject] = useState("");
  const [notes, setNotes] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [texts, setTexts] = useState([]);
  const [emails, setEmails] = useState([]);
  const [currentTab, setCurrentTab] = useState("info");
  const [enrolledItems, setEnrolledItems] = useState(new Set());
  const [phones, setPhones] = useState([]);
  const [leadEmail, setLeadEmail] = useState("");
  const [leadPhone, setLeadPhone] = useState("");
  const phoneUpdated = useRef(false);
  const emailUpdated = useRef(false);

  const services = [
    "Weekly Pool Care",
    "One Time Clean",
    "Repair",
    "Leak Detection",
    "Remodel",
    "Degreen",
  ];

  const onEnrolledChange = (serviceName, isEnrolled, formData) => {
    setEnrolledItems((prevEnrolledItems) => {
      let newEnrolledItems = new Map(prevEnrolledItems);
      if (isEnrolled) {
        // Store entire formData under the service name key
        newEnrolledItems.set(serviceName, formData);
      } else {
        // Remove the service if it is no longer enrolled
        newEnrolledItems.delete(serviceName);
      }

      return newEnrolledItems;
    });
  };

  function findEmailThreads(emails) {
    // Map to hold email message IDs as keys and their respective thread index as values
    const messageIdToThreadIndex = new Map();

    // Array to store threads
    const threads = [];

    // Iterate over the array of emails
    emails.forEach((email) => {
      const references = email.references;
      const messageId = email.messageId;

      if (references) {
        // Split references by commas to handle multiple references
        const referenceArray = references.split(",");

        // Check if any reference already belongs to an existing thread
        let threadIndex = -1;

        // Find an existing thread index that any of the references belong to
        for (const ref of referenceArray) {
          if (messageIdToThreadIndex.has(ref.trim())) {
            threadIndex = messageIdToThreadIndex.get(ref.trim());
            break;
          }
        }

        if (threadIndex === -1) {
          // If no existing thread, create a new one
          threadIndex = threads.length;
          threads.push([]);
        }

        // Add the current email to the identified thread
        threads[threadIndex].push(email);

        // Map the current email's messageId to the thread index
        messageIdToThreadIndex.set(messageId, threadIndex);
      } else {
        // If no references, treat it as a new thread
        const newThreadIndex = threads.length;
        threads.push([email]);
        messageIdToThreadIndex.set(messageId, newThreadIndex);
      }
    });

    return threads;
  }

  const getTextsAndEmails = async () => {
    let unsubscribeEmail = null;
    let unsubscribeText = null;

    // Function to safely unsubscribe to avoid memory leaks
    const safeUnsubscribe = (unsubscribe) => {
      if (unsubscribe) {
        unsubscribe();
      }
    };

    if (props.leadData.leadEmail) {
      if (props.leadData.source.toLowerCase() === "direct") {
        const emailDocRef = db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(
            props.leadData.leadType === "email"
              ? props.leadData.leadEmail
              : props.leadData.phone.includes("+1")
              ? props.leadData.phone
              : "+1" + props.leadData.phone
          );

        // Start listening to the email document
        unsubscribeEmail = emailDocRef.onSnapshot(
          (emailDoc) => {
            if (emailDoc.exists) {
              console.log("Listening to email lead changes...");

              const data = emailDoc.data();

              if (!data || !data.emails) {
                console.log("No emails found");
                return;
              }

              setEmails(data.emails);
            } else {
              console.log("No email lead found. Stopping listener.");
              safeUnsubscribe(unsubscribeEmail);
            }
          },
          (error) => {
            console.error("Error listening to email lead changes:", error);
          }
        );
      } else {
        const emailDocRef = db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(props.leadData.source)
          .collection("Leads")
          .doc(props.leadData.leadId);

        // Start listening to the email document
        unsubscribeEmail = emailDocRef.onSnapshot(
          (emailDoc) => {
            if (emailDoc.exists) {
              console.log("Listening to email lead changes...");
              const data = emailDoc.data();
              setEmails(data.emails || []);
            } else {
              console.log("No email lead found. Stopping listener.");
              safeUnsubscribe(unsubscribeEmail);
            }
          },
          (error) => {
            console.error("Error listening to email lead changes:", error);
          }
        );
      }
    }

    if (props.leadData.phone) {
      if (props.leadData.source === "Direct") {
        const emailDocRef = db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(
            props.leadData.leadType === "email"
              ? props.leadData.leadEmail
              : props.leadData.phone.includes("+1")
              ? props.leadData.phone
              : "+1" + props.leadData.phone
          );

        // Start listening to the email document
        unsubscribeEmail = emailDocRef.onSnapshot(
          (emailDoc) => {
            if (emailDoc.exists) {
              console.log("Listening to phone lead changes...");
              const data = emailDoc.data();
              setTexts(data.texts || []);
            } else {
              console.log("No phone lead found. Stopping listener.");
              safeUnsubscribe(unsubscribeEmail);
            }
          },
          (error) => {
            console.error("Error listening to phone lead changes:", error);
          }
        );
      } else {
        const emailDocRef = db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(props.leadData.source)
          .collection("Leads")
          .doc(props.leadData.leadId);

        // Start listening to the email document
        unsubscribeEmail = emailDocRef.onSnapshot(
          (emailDoc) => {
            if (emailDoc.exists) {
              console.log("Listening to phone lead changes...");
              const data = emailDoc.data();
              setTexts(data.texts || []);
            } else {
              console.log("No phone lead found. Stopping listener.");
              safeUnsubscribe(unsubscribeEmail);
            }
          },
          (error) => {
            console.error("Error listening to phone lead changes:", error);
          }
        );
      }
    }

    // Return a cleanup function to unsubscribe from both listeners when the component unmounts or when re-invoking this effect
    return () => {
      safeUnsubscribe(unsubscribeEmail);
      safeUnsubscribe(unsubscribeText);
    };
  };

  useEffect(() => {
    setOpen(props.open);

    if (props.leadData) {
      setLeadData(props.leadData);
      setLeadEmail(props.leadData.leadEmail);
      setLeadPhone(props.leadData.phone);
      setSelectedOption(props.leadData.option);
    }

    if (props.leadProfileOpenPage === "email") {
      setViewEmailOpen(true);
    } else if (props.leadProfileOpenPage === "sms") {
      setCurrentTab("sms");
    }
    if (props.open) {
      getTextsAndEmails();
      db.collection("Jared")
        .doc("Pooli")
        .collection("Phones")
        .doc("Numbers")
        .get()
        .then((doc) => {
          if (doc.exists) {
            setPhones(doc.data().Numbers);
          }
        });
    }
  }, [props.open]);

  const handleClose = async () => {
    await handleMarkAllTextsAsRead();
    if (emailUpdated.current) {
      console.log("updating email");
      updateEmail();
      emailUpdated.current = false;
    }
    if (phoneUpdated.current) {
      updatePhone();
      console.log("updating phone");
      phoneUpdated.current = false;
    }
    setEmails([]);
    setTexts([]);
    setOpen(false);
    props.setOpen(false);
  };

  const updateEmail = async () => {
    const response = await fetch(
      "https://us-central1-symbri-production.cloudfunctions.net/checkLeadExists",
      {
        method: "POST",
        body: JSON.stringify({
          finalObject: {
            time: dayjs().unix(),
            formattedTime: dayjs().format("MM/DD/YYYY"),
            firstName: null,
            lastName: null,
            leadEmail: leadEmail,
            phone: null,
            address: null,
            category: "lead",
            source: "Direct",
            leadId: leadData.leadId,
            emails: [],
            pets: null,
            gateCode: null,
            ingroundAboveGround: null,
            zipcode: null,
          },
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      if (data.match) {
        alert("Cannot change lead to this email. Email already exists.");
        return;
      }
    }

    setLeadData({ ...leadData, ["leadEmail"]: leadEmail });

    if (leadData.source.toLowerCase() === "direct") {
      let leadType = "";

      if (leadData.leadEmail && leadData.leadEmail.length > 0) {
        const leadEmailRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.leadEmail)
          .get();
        if (leadEmailRef.exists) {
          leadType = "email";
        }
      }

      if (leadData.phone && leadData.phone.length > 0) {
        const leadTextRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.phone)
          .get();
        if (leadTextRef.exists) {
          leadType = "text";
        }
      }
      console.log(leadType);
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(leadType === "email" ? leadData.leadEmail : leadData.phone)
        .update({ ["leadEmail"]: leadEmail });
    } else {
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(leadData.source)
        .collection("Leads")
        .doc(leadData.leadId)
        .update({ ["leadEmail"]: leadEmail });
    }
  };

  const updatePhone = async () => {
    const response = await fetch(
      "https://us-central1-symbri-production.cloudfunctions.net/checkLeadExists",
      {
        method: "POST",
        body: JSON.stringify({
          finalObject: {
            time: dayjs().unix(),
            formattedTime: dayjs().format("MM/DD/YYYY"),
            firstName: null,
            lastName: null,
            leadEmail: null,
            phone: leadPhone,
            address: null,
            category: "lead",
            source: "Direct",
            leadId: leadData.leadId,
            emails: [],
            pets: null,
            gateCode: null,
            ingroundAboveGround: null,
            zipcode: null,
          },
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      if (data.match) {
        alert("Cannot change lead to this email. Email already exists.");
        return;
      }
    }

    setLeadData({ ...leadData, ["phone"]: leadPhone });

    if (leadData.source.toLowerCase() === "direct") {
      let leadType = "";

      if (leadData.leadEmail && leadData.leadEmail.length > 0) {
        const leadEmailRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.leadEmail)
          .get();
        if (leadEmailRef.exists) {
          leadType = "email";
        }
      }

      if (leadData.phone && leadData.phone.length > 0) {
        const leadTextRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.phone)
          .get();
        if (leadTextRef.exists) {
          leadType = "text";
        }
      }
      console.log(leadType);
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(leadType === "email" ? leadData.leadEmail : leadData.phone)
        .update({ ["phone"]: leadPhone });
    } else {
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(leadData.source)
        .collection("Leads")
        .doc(leadData.leadId)
        .update({ ["phone"]: leadPhone });
    }
  };

  const updateLead = async (updateData) => {
    // need to update leadData state as well

    setLeadData({ ...leadData, [updateData.fieldName]: updateData.value });

    if (leadData.source.toLowerCase() === "direct") {
      let leadType = "";

      if (leadData.leadEmail && leadData.leadEmail.length > 0) {
        const leadEmailRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.leadEmail)
          .get();
        if (leadEmailRef.exists) {
          leadType = "email";
        }
      }

      if (leadData.phone && leadData.phone.length > 0) {
        const leadTextRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.phone)
          .get();
        if (leadTextRef.exists) {
          leadType = "text";
        }
      }
      console.log(leadType);
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(leadType === "email" ? leadData.leadEmail : leadData.phone)
        .update({ [updateData.fieldName]: updateData.value });
    } else {
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(leadData.source)
        .collection("Leads")
        .doc(leadData.leadId)
        .update({ [updateData.fieldName]: updateData.value });
    }
  };

  const handleDeleteLead = async () => {
    // need to update leadData state as well

    if (leadData.source.toLowerCase() === "direct") {
      let leadType = "";

      if (leadData.leadEmail && leadData.leadEmail.length > 0) {
        const leadEmailRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.leadEmail)
          .get();
        if (leadEmailRef.exists) {
          leadType = "email";
        }
      }

      if (leadData.phone && leadData.phone.length > 0) {
        const leadTextRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.phone)
          .get();
        if (leadTextRef.exists) {
          leadType = "text";
        }
      }
      console.log(leadType);
      console.log(leadData.leadEmail);
      console.log(leadData.phone);
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(leadType === "email" ? leadData.leadEmail : leadData.phone)
        .delete();
    } else {
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(leadData.source)
        .collection("Leads")
        .doc(leadData.leadId)
        .delete();
    }
    handleClose();
  };

  const handleViewEmail = async (email) => {
    setViewEmailOpen(true);
    console.log(email);
    setEmailData(email);

    return;

    if (!email[email.length - 1].viewed) {
      const updatedEmails = leadData.emails.map((thread) => {
        const eml = thread;
        return { ...eml, viewed: true };
      });

      console.log(updatedEmails);

      setLeadData({ ...leadData, emails: updatedEmails });

      const leadUpdatePath =
        leadData.source.toLowerCase() === "direct"
          ? leadData.leadType === "sms"
            ? db
                .collection("Jared")
                .doc("Pooli")
                .collection("Leads")
                .doc(
                  leadData.phone.includes("+1")
                    ? leadData.phone
                    : "+1" + leadData.phone
                )
            : db
                .collection("Jared")
                .doc("Pooli")
                .collection("Leads")
                .doc(leadData.leadEmail)
          : db
              .collection("Jared")
              .doc("Pooli")
              .collection("Leads")
              .doc(leadData.source)
              .collection("Leads")
              .doc(leadData.leadId);

      leadUpdatePath
        .update({ emails: updatedEmails })
        .then(() => {
          console.log("Email viewed status updated successfully");
        })
        .catch((error) => {
          console.error("Error updating email viewed status: ", error);
        });
    }
  };

  const handleOptionChange = (option) => {};

  const handleMarkAllTextsAsRead = async () => {
    const updatedTexts = texts.map((text) => {
      return { ...text, viewed: true };
    });

    setTexts(updatedTexts);

    try {
      if (leadData.source.toLowerCase() === "direct") {
        let leadType = "";

        if (leadData.leadEmail.length > 0) {
          const leadEmailRef = await db
            .collection("Jared")
            .doc("Pooli")
            .collection("Leads")
            .doc(leadData.leadEmail)
            .get();

          if (leadEmailRef.exists) {
            leadType = "email";
          }
        }

        if (leadData.phone.length > 0) {
          const leadTextRef = await db
            .collection("Jared")
            .doc("Pooli")
            .collection("Leads")
            .doc(leadData.phone)
            .get();

          if (leadTextRef.exists) {
            leadType = "text";
          }
        }

        await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadType === "email" ? leadData.leadEmail : leadData.phone)
          .update({ texts: updatedTexts });
      } else {
        await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.source)
          .collection("Leads")
          .doc(leadData.leadId)
          .update({ texts: updatedTexts });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitServices = async () => {
    const formData = Object.fromEntries(enrolledItems);
    // convert formData to array

    const services = Object.keys(formData).map((key) => {
      return { [key]: formData[key] };
    });

    // need to reload the services in the leadData state

    setLeadData({ ...leadData, services: services });

    if (leadData.source.toLowerCase() === "direct") {
      let leadType = "";

      if (leadData.leadEmail && leadData.leadEmail.length > 0) {
        const leadEmailRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.leadEmail)
          .get();
        if (leadEmailRef.exists) {
          leadType = "email";
        }
      }

      if (leadData.phone && leadData.phone.length > 0) {
        const leadTextRef = await db
          .collection("Jared")
          .doc("Pooli")
          .collection("Leads")
          .doc(leadData.phone)
          .get();
        if (leadTextRef.exists) {
          leadType = "text";
        }
      }

      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(leadType === "email" ? leadData.leadEmail : leadData.phone)
        .update({
          services: services,
          status: services.some((a) => Object.keys(a)[0] === "Weekly Pool Care")
            ? "done"
            : leadData.status,
        });
    } else {
      console.log(
        services.some((a) => Object.keys(a)[0] === "Weekly Pool Care")
      );
      db.collection("Jared")
        .doc("Pooli")
        .collection("Leads")
        .doc(leadData.source)
        .collection("Leads")
        .doc(leadData.leadId)
        .update({
          services: services,
          status: services.some((a) => Object.keys(a)[0] === "Weekly Pool Care")
            ? "done"
            : leadData.status,
        });
    }

    /*
    const response = await fetch(
      "https://hooks.zapier.com/hooks/catch/5649060/3nsa2dk/",
      {
        method: "POST",
        body: JSON.stringify({ services: formData, leadData: leadData }),
      }
    );

        if (response.ok) {
      console.log("Services submitted successfully");
    }
    */
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => console.log("")}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  {leadData && (
                    <div className="flex h-full flex-col bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-[#202937] py-6 px-4 sm:px-6 border border-white border-b-0">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Lead Profile
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={handleClose}
                              >
                                <span className="sr-only">Close panel</span>
                                <XCircleIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              View the details of a lead here.
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            backgroundColor: "#202937",
                            height: 60,
                            alignItems: "center",
                            borderBottom: "1px solid #FFFFFF",
                            borderLeft: "1px solid #FFFFFF",
                            borderRight: "1px solid #FFFFFF",
                          }}
                        >
                          <div
                            onClick={() => setCurrentTab("info")}
                            style={{
                              width: 80,
                              height: 30,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: 10,
                              color:
                                currentTab === "info" ? "white" : "#8694CE",
                              cursor: "pointer",
                            }}
                          >
                            Info
                          </div>
                          <div
                            onClick={() => setCurrentTab("service")}
                            style={{
                              width: 80,
                              height: 30,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color:
                                currentTab === "service" ? "white" : "#8694CE",
                              cursor: "pointer",
                            }}
                          >
                            Service
                          </div>
                          {props.leadData.phone && (
                            <div
                              onClick={() => setCurrentTab("sms")}
                              style={{
                                width: 80,
                                height: 30,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color:
                                  currentTab === "sms" ? "white" : "#8694CE",
                                cursor: "pointer",
                              }}
                            >
                              SMS
                            </div>
                          )}
                          {props.leadData.leadEmail && (
                            <div
                              onClick={() => setViewEmailOpen(true)}
                              style={{
                                width: 80,
                                height: 30,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color:
                                  currentTab === "email" ? "white" : "#8694CE",
                                cursor: "pointer",
                              }}
                            >
                              E-Mail
                            </div>
                          )}
                          <div
                            onClick={() => setCurrentTab("notes")}
                            style={{
                              width: 80,
                              height: 30,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color:
                                currentTab === "notes" ? "white" : "#8694CE",
                              cursor: "pointer",
                            }}
                          >
                            Notes
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    First Name
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={
                                        leadData.firstName
                                          ? leadData.firstName
                                          : ""
                                      }
                                      onChange={(e) =>
                                        updateLead({
                                          fieldName: "firstName",
                                          value: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Last Name
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={
                                        leadData.lastName
                                          ? leadData.lastName
                                          : ""
                                      }
                                      onChange={(e) =>
                                        updateLead({
                                          fieldName: "lastName",
                                          value: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Address
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={
                                        leadData.address ? leadData.address : ""
                                      }
                                      onChange={(e) =>
                                        updateLead({
                                          fieldName: "address",
                                          value: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Zipcode
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={
                                        leadData.zipcode ? leadData.zipcode : ""
                                      }
                                      onChange={(e) =>
                                        updateLead({
                                          fieldName: "zipcode",
                                          value: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              )}

                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Email
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      id="leadName"
                                      disabled={
                                        !leadData.leadEmail
                                          ? false
                                          : leadData.leadType === "email"
                                      }
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={leadEmail}
                                      onChange={(e) => {
                                        setLeadEmail(e.target.value);

                                        emailUpdated.current = true;
                                      }}
                                    />
                                  </div>
                                </div>
                              )}

                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Phone
                                  </label>

                                  <div className="mt-1">
                                    <input
                                      id="leadName"
                                      disabled={leadData.leadType === "sms"}
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={leadPhone}
                                      onChange={(e) => {
                                        setLeadPhone(e.target.value);
                                        phoneUpdated.current = true;
                                      }}
                                    />
                                  </div>
                                </div>
                              )}

                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Gate Code
                                  </label>

                                  <div className="mt-1">
                                    <input
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={
                                        leadData.gateCode
                                          ? leadData.gateCode
                                          : ""
                                      }
                                      onChange={(e) =>
                                        updateLead({
                                          fieldName: "gateCode",
                                          value: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="pets"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Aggressive Pets
                                  </label>
                                  <div className="mt-1">
                                    <select
                                      id="pets"
                                      className="block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={leadData.pets ? leadData.pets : ""}
                                      onChange={(e) =>
                                        updateLead({
                                          fieldName: "pets",
                                          value: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="none">
                                        Select an option
                                      </option>
                                      <option value="true">Yes</option>
                                      <option value="false">No</option>
                                    </select>
                                  </div>
                                </div>
                              )}

                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    In Ground / Above Ground
                                  </label>

                                  <div className="mt-1">
                                    <input
                                      id="leadName"
                                      className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      value={
                                        leadData.ingroundAboveGround
                                          ? leadData.ingroundAboveGround
                                          : ""
                                      }
                                      onChange={(e) =>
                                        updateLead({
                                          fieldName: "ingroundAboveGround",
                                          value: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              )}

                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Date
                                  </label>
                                  <div className="mt-1">
                                    {dayjs(leadData.time * 1000).format(
                                      "MMMM D, YYYY h:mm A"
                                    )}
                                  </div>
                                </div>
                              )}
                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Source
                                  </label>
                                  <div className="mt-1">{leadData.source}</div>
                                </div>
                              )}

                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Last Contact Date
                                  </label>
                                  <div className="mt-1">
                                    {leadData && (
                                      <div>
                                        {dayjs(
                                          Math.max(
                                            leadData.emails?.[
                                              leadData.emails.length - 1
                                            ]?.time ?? 0,
                                            leadData.texts?.[
                                              leadData.texts.length - 1
                                            ]?.time ?? 0
                                          ) * 1000
                                        ).format("MMMM D, YYYY h:mm A")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Lead Type
                                  </label>
                                  <div className="mt-1">
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="maintenance"
                                        name="options"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "leadType",
                                              value: "weekly",
                                            });
                                          }
                                        }}
                                        checked={leadData.leadType === "weekly"}
                                      />
                                      <label
                                        htmlFor="maintenance"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Weekly Pool Care
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="maintenance"
                                        name="options"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "leadType",
                                              value: "oneTime",
                                            });
                                          }
                                        }}
                                        checked={
                                          leadData.leadType === "oneTime"
                                        }
                                      />
                                      <label
                                        htmlFor="maintenance"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        One Time Clean
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="repair"
                                        name="options"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "leadType",
                                              value: "repair",
                                            });
                                          }
                                        }}
                                        checked={leadData.leadType === "repair"}
                                      />
                                      <label
                                        htmlFor="repair"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Repair
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="maintenance"
                                        name="options"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "leadType",
                                              value: "leak",
                                            });
                                          }
                                        }}
                                        checked={leadData.leadType === "leak"}
                                      />
                                      <label
                                        htmlFor="maintenance"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Leak Detection
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="maintenance"
                                        name="options"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "leadType",
                                              value: "remodel",
                                            });
                                          }
                                        }}
                                        checked={
                                          leadData.leadType === "remodel"
                                        }
                                      />
                                      <label
                                        htmlFor="maintenance"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Remodel
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="degreen"
                                        name="options"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "leadType",
                                              value: "degreen",
                                            });
                                          }
                                        }}
                                        checked={
                                          leadData.leadType === "degreen"
                                        }
                                      />
                                      <label
                                        htmlFor="degreen"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Degreen
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Status
                                  </label>
                                  <div className="mt-1">
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="maintenance"
                                        name="options"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "saleStatus",
                                              value: "selling",
                                            });
                                          }
                                        }}
                                        checked={
                                          leadData.saleStatus === "selling"
                                        }
                                      />
                                      <label
                                        htmlFor="maintenance"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Selling
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="maintenance"
                                        name="options"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "saleStatus",
                                              value: "lost",
                                            });
                                          }
                                        }}
                                        checked={leadData.saleStatus === "lost"}
                                      />
                                      <label
                                        htmlFor="maintenance"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Lost
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {currentTab === "info" && (
                                <div>
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Lost Reason
                                  </label>
                                  <div className="mt-1">
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="maintenance"
                                        name="options"
                                        checked={
                                          leadData.lostReason ===
                                          "Price too high"
                                        }
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "lostReason",
                                              value: "Price too high",
                                            });
                                          }
                                        }}
                                      />
                                      <label
                                        htmlFor="maintenance"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Price too high
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="maintenance"
                                        name="options"
                                        checked={
                                          leadData.lostReason ===
                                          "Found someone else"
                                        }
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "lostReason",
                                              value: "Found someone else",
                                            });
                                          }
                                        }}
                                      />
                                      <label
                                        htmlFor="maintenance"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Found someone else
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="repair"
                                        name="options"
                                        checked={
                                          leadData.lostReason === "Unknown"
                                        }
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "lostReason",
                                              value: "Unknown",
                                            });
                                          }
                                        }}
                                      />
                                      <label
                                        htmlFor="repair"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Unknown
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="maintenance"
                                        name="options"
                                        checked={
                                          leadData.lostReason ===
                                          "Service needed not provided"
                                        }
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "lostReason",
                                              value:
                                                "Service needed not provided",
                                            });
                                          }
                                        }}
                                      />
                                      <label
                                        htmlFor="maintenance"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Service needed not provided
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="maintenance"
                                        name="options"
                                        checked={
                                          leadData.lostReason ===
                                          "Price shopping"
                                        }
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "lostReason",
                                              value: "Price shopping",
                                            });
                                          }
                                        }}
                                      />
                                      <label
                                        htmlFor="maintenance"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Price shopping
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="degreen"
                                        name="options"
                                        checked={
                                          leadData.lostReason ===
                                          "They dont have a pool"
                                        }
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "lostReason",
                                              value: "They dont have a pool",
                                            });
                                          }
                                        }}
                                      />
                                      <label
                                        htmlFor="degreen"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        They dont have a pool
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        id="degreen"
                                        name="options"
                                        checked={
                                          leadData.lostReason ===
                                          "Bad above ground"
                                        }
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateLead({
                                              fieldName: "lostReason",
                                              value: "Bad above ground",
                                            });
                                          }
                                        }}
                                      />
                                      <label
                                        htmlFor="degreen"
                                        className="text-sm font-medium text-gray-900 ml-2"
                                      >
                                        Bad above ground
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {currentTab === "notes" && (
                                <div>
                                  <div>
                                    <label
                                      htmlFor="description"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Sales Notes
                                    </label>
                                    <div className="mt-1">
                                      <textarea
                                        id="leadName"
                                        style={{ height: 200 }}
                                        className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={
                                          leadData.notes ? leadData.notes : ""
                                        }
                                        onChange={(e) =>
                                          updateLead({
                                            fieldName: "notes",
                                            value: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="mt-4">
                                    <label
                                      htmlFor="description"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Service Notes
                                    </label>
                                    <div className="mt-1">
                                      <textarea
                                        id="leadName"
                                        style={{ height: 200 }}
                                        className="mt-1 block w-full border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        value={
                                          leadData.serviceNotes
                                            ? leadData.serviceNotes
                                            : ""
                                        }
                                        onChange={(e) =>
                                          updateLead({
                                            fieldName: "serviceNotes",
                                            value: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {currentTab === "sms" && (
                                <div className="flex flex-col">
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    SMS
                                  </label>
                                  <SMS
                                    texts={texts}
                                    leadData={leadData}
                                    textMessage={textMessage}
                                    setTextMessage={setTextMessage}
                                    phones={phones}
                                  />
                                </div>
                              )}
                              {currentTab === "service" && (
                                <div className="flex flex-col">
                                  <div className="mt-1">
                                    {services.map((service) => (
                                      <ServiceCard
                                        leadData={leadData}
                                        enrolledItems={enrolledItems}
                                        onEnrolledChange={onEnrolledChange}
                                        serviceName={service}
                                        setLeadData={setLeadData}
                                        zipcodes={props.zipcodes}
                                      />
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        {currentTab !== "service" && (
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-red-500 py-2 px-4 text-sm font-medium text-white shadow-sm mr-4"
                            onClick={() => handleDeleteLead()}
                          >
                            Delete
                          </button>
                        )}

                        {currentTab === "service" && (
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-[#202937] py-2 px-4 text-sm font-medium text-white shadow-sm mr-4"
                            onClick={() => handleSubmitServices()}
                          >
                            Submit
                          </button>
                        )}

                        {currentTab === "email" && (
                          <button
                            type="button"
                            style={{
                              display: leadData.leadEmail ? "initial" : "none",
                            }}
                            className="rounded-md border border-gray-300 bg-[#202937] py-2 px-4 text-sm font-medium text-white shadow-sm mr-4"
                            onClick={() => setNewEmailOpen(true)}
                          >
                            New Email
                          </button>
                        )}

                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => handleClose(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          {viewEmailOpen && (
            <ViewEmail
              emailData={emailData}
              emails={emails}
              open={viewEmailOpen}
              leadData={leadData}
              setOpen={setViewEmailOpen}
              originalMessageId={originalMessageId}
              emailSubject={emailSubject}
            />
          )}
          {newEmailOpen && (
            <NewEmail
              emailData={emailData}
              open={newEmailOpen}
              leadData={leadData}
              setOpen={setNewEmailOpen}
            />
          )}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
